/* ==========================================================================
 font-size
========================================================================== */

/* font-size */
@for $value from 0 to 50 {
  .u-fs-#{$value} {
    font-size: $value * 0.1 + rem !important;
  }
}

@media (--mobile) {
  /* font-size */
  @for $value from 0 to 50 {
    .u-fs-sp-#{$value} {
      font-size: $value * 0.1 + rem !important;
    }
  }
}
