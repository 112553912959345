.js-inview {
  &[data-inview="fade"] {
    opacity: 0;
    transition: opacity 1s;

    &.is-inview {
      opacity: 1;
    }
  }

  &[data-inview="fade-top"] {
    opacity: 0;
    transition: opacity 1s, transform 1s;
    transform: translateY(30px);

    &.is-inview {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &[data-inview="fade-left"] {
    opacity: 0;
    transition: opacity 0.5s, transform 0.5s;
    transform: translateX(-30px);

    &.is-inview {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &[data-inview="fade-right"] {
    opacity: 0;
    transition: opacity 0.5s, transform 0.5s;
    transform: translateX(30px);

    &.is-inview {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &[data-inview="fade-bottom"] {
    opacity: 0;
    transition: opacity 0.8s, transform 0.8s;
    transform: translateY(-30px);

    &.is-inview {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &[data-inview="btn"] {
    a,
    button {
      &::before {
        transition: transform 0.4s;
        transform: scale(0, 1);
        transform-origin: left top;
      }
    }

    span {
      opacity: 0;
      transition: opacity 0.5s 0.3s, transform 0.5s 0.3s;
      transform: translateY(10px);
    }

    i {
      opacity: 0;
      transition: opacity 0.5s 0.3s, transform 0.5s 0.3s;
      transform: translateY(10px);
    }

    &.is-inview {
      a,
      button {
        &::before {
          transform: scale(1, 1);
        }
      }

      span {
        opacity: 1;
        transform: translateY(0);
      }

      i {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &[data-inview="btn-border"] {
    a,
    button {
      &::after {
        transition: transform 0.4s;
        transform: scale(0, 1);
        transform-origin: left top;
      }
    }

    span {
      opacity: 0;
      transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
      transform: translateY(10px);
    }

    &.is-inview {
      a,
      button {
        &::after {
          transform: scale(1, 1);
        }
      }

      span {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &[data-inview="bg"] {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      transition: transform 0.4s;
      transform: scale(1, 1);
      transform-origin: right top;
    }

    &.is-inview {
      &::after {
        transform: scale(0, 1);
      }
    }
  }

  &[data-inview="bg-white"] {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background-color: #fff;
      transition: transform 0.4s;
      transform: scale(1, 1);
      transform-origin: right top;
    }

    &.is-inview {
      &::after {
        transform: scale(0, 1);
      }
    }
  }

  &[data-inview="bg-scale"] {
    &::before {
      transition: transform 0.8s;
      transform: translate(-50%, -50%) scale(0.5);
      transform-origin: center center;
    }

    &.is-inview {
      &::before {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  &-photo[data-inview="scale-small"] {
    transition: transform 1s;
    transform: scale(1.5);

    @at-root .is-inview & {
      transform: scale(1);
    }
  }

  &-photo-img[data-inview="scale-small"] {
    transition: transform 1s;
    transform: translateX(-50%) scale(1.5);

    @at-root .is-inview & {
      transform: translateX(-50%) scale(1);
    }
  }

  &-eachItem {
    &[data-inview="fade"] {
      opacity: 0;
      transition: opacity 0.8s;

      &.is-inview {
        opacity: 1;
      }
    }

    &[data-inview="fade-top"] {
      opacity: 0;
      transition: opacity 0.8s, transform 0.8s;
      transform: translateY(30px);

      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &[data-inview="fade-bottom"] {
      opacity: 0;
      transition: opacity 0.8s, transform 0.8s;
      transform: translateY(-30px);

      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &[data-inview="fade-right"] {
      opacity: 0;
      transition: opacity 0.8s, transform 0.8s;
      transform: translateX(30px);

      &.is-inview {
        opacity: 1;
        transform: translateX(0);
      }
    }

    &[data-inview="btn"] {
      a,
      button {
        &::before {
          transition: transform 0.4s;
          transform: scale(0, 1);
          transform-origin: left top;
        }
      }

      span {
        opacity: 0;
        transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
        transform: translateY(10px);
      }

      &.is-inview {
        a,
        button {
          &::before {
            transform: scale(1, 1);
          }
        }

        span {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    &[data-inview="btn-border"] {
      a,
      button {
        &::after {
          transition: transform 0.4s;
          transform: scale(0, 1);
          transform-origin: left top;
        }
      }

      span {
        opacity: 0;
        transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
        transform: translateY(10px);
      }

      &.is-inview {
        a,
        button {
          &::after {
            transform: scale(1, 1);
          }
        }

        span {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    &[data-inview="list-border"] {
      &::before {
        transition: transform 0.8s;
        transform: scale(0, 1);
        transform-origin: left top;
      }

      span,
      figure,
      a {
        opacity: 0;
        transition: opacity 0.8s, transform 0.8s;
        transform: translateY(30px);
      }

      i {
        opacity: 0;
        transition: opacity 0.8s, transform 0.8s;
        transform: translateX(-20px) rotate(45deg);
      }

      &.is-inview {
        &::before {
          transform: scale(1);
        }

        span,
        figure,
        a {
          opacity: 1;
          transform: translateY(0);
        }

        i {
          opacity: 1;
          transform: translateX(-10px) rotate(45deg);
        }
      }
    }
  }

  &-eachItem-fast {
    &[data-inview="fade-top"] {
      opacity: 0;
      transition: opacity 0.5s, transform 0.5s;
      transform: translateY(10px);

      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &-eachItem-delay {
    &[data-inview="fade"] {
      opacity: 0;
      transition: transform 0.8s;

      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &[data-inview="fade-top"] {
      opacity: 0;
      transition: opacity 0.8s, transform 0.8s;
      transform: translateY(30px);

      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &[data-inview="fade-right"] {
      opacity: 0;
      transition: opacity 0.8s, transform 0.8s;
      transform: translateX(30px);

      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &[data-inview="fade-bottom"] {
      opacity: 0;
      transition: opacity 0.8s, transform 0.8s;
      transform: translateY(-30px);

      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.js-inview-all {
  &[data-inview="btn"] {
    a,
    button {
      &::before {
        transition: transform 0.4s;
        transform: scale(0, 1);
        transform-origin: left top;
      }
    }

    span {
      opacity: 0;
      transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
      transform: translateY(10px);
    }

    &.is-inview {
      a,
      button {
        &::before {
          transform: scale(1, 1);
        }
      }

      span {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &[data-inview="btn-border"] {
    a,
    button {
      &::after {
        transition: transform 0.4s;
        transform: scale(0, 1);
        transform-origin: left top;
      }
    }

    span {
      opacity: 0;
      transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
      transform: translateY(10px);
    }

    &.is-inview {
      a,
      button {
        &::after {
          transform: scale(1, 1);
        }
      }

      span {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

_:-ms-lang(x)::-ms-backdrop,
.js-inview-photo[data-inview="scale-small"] {
  transform: scale(1);
}

_:-ms-lang(x)::-ms-backdrop,
.js-inview-photo-img[data-inview="scale-small"] {
  transform: translateX(-50%) scale(1);
}
