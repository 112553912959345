.u-1col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-2col {
  display: unquote("-webkit-box");
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@media (--large) {
  .u-1col-pc {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .u-2col-pc {
    display: unquote("-webkit-box");
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

@media (--mobile) {
  .u-1col-sp {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .u-2col-sp {
    display: unquote("-webkit-box");
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
