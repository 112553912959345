.p-service {
  &-section-header {
    @apply
      rounded-l-[3rem]
      overflow-hidden
      relative
      h-auto
      before:pt-[45%]
      before:block
      sp:rounded-[0]
      sp:before:pt-[85%]
      ;
    &__bg {
      @apply
        w-full
        h-full
        absolute
        inset-0
        object-cover
        ;
    }
    &__nav {
      @apply
        absolute
        top-[50%]
        left-[50%]
        translate-x-[-50%]
        translate-y-[-50%]
        z-[1]
        bg-[rgba(255,255,255,0.9)]
        rounded-[2rem]
        text-center
        pt-[6rem]
        pb-[2rem]
        px-[2rem]
        w-[22rem]
      ;
    }
    &__icon {
      @apply
        w-[7rem]
        h-[7rem]
        absolute
        top-[-2.4rem]
        left-[50%]
        translate-x-[-50%]
      ;
    }
    &__ttl {
      @apply
        flex
        flex-col
        items-center
        gap-[0]
        mb-[1rem]
      ;
      &-sub {
        @apply
          text-[1.4rem]
          text-[#B5B5B6]
        ;
      }
      &-main {
        @apply
          text-[2.54rem]
          inline-block
          relative
          before:absolute
          before:block
          before:w-full
          before:h-[.3rem]
          before:bg-[#FFE300]
          before:bottom-[0]
          before:left-[0]
        ;
      }
    }
    &__nav-list {
      @apply
        flex
        flex-col
        gap-[.5rem]
        w-[12rem]
        mx-auto
        [&>li]:w-full
        [&>li]:h-[3rem]
        [&>li]:flex
        [&>li]:items-center
        [&>li]:justify-center
        [&>li]:rounded-[1.4rem]
        [&>li]:bg-[#FCEA00]
        [&>li]:text-[1.3rem]
        ;
    }
  }
  &-dots-bg {
    @apply
      before:bg-[white]
      before:w-full
      before:h-[2px]
      before:absolute
      before:left-0
      before:top-[1.2rem]
    ;
    &:before {
      background-image: linear-gradient(to right, #B5B5B6 2px, transparent 2px);
      background-size: 7px;
    }
  }
  &-accordion {
    @apply
      pr-[6rem]
      flex
      flex-col
      mb-[1.6rem]

      sp:pr-[0]
    ;
    &__ttl {
      @apply
        w-full
        flex
        items-center
        justify-between
        px-[2rem]
        bg-[#FCEA00]
        rounded-[.5rem]
        h-[5.4rem]
        text-[1.8rem]
        cursor-pointer

        sp:leading-[1.8]
        sp:pr-[1rem]
      ;
    }
    &__toggle {
      @apply
        text-[1.3rem]
        flex
        items-center
        bg-[white]
        rounded-[1.3rem]
        gap-[1rem]
        px-[1.2rem]
        h-[2.6rem]
        pointer-events-none

        sp:rounded-[100%]
        sp:px-[0]
        sp:w-[3rem]
        sp:h-[3rem]
        sp:justify-center
      ;
      &-mark {
        @apply
          block
          relative
          w-[1.1rem]
          h-[1.1rem]
          before:w-[100%]
          before:h-[1px]
          before:bg-[#231815]
          before:absolute
          before:left-[0]
          before:top-[50%]
          after:h-[100%]
          after:w-[1px]
          after:bg-[#231815]
          after:absolute
          after:top-[0]
          after:left-[50%]
          before:origin-center
          after:origin-center
          before:translate-y-[-50%]
          after:translate-x-[-50%]
          before:duration-[.3s]
          after:duration-[.3s]
        ;
        &.is-active {
          @apply
            before:rotate-[45deg]
            before:top-[50%]
            
            after:rotate-[45deg]
            after:left-[50%]
            
          ;
        }
      }
    }
    &__data {
      @apply
        p-[3rem]
        hidden

        sp:py-[2rem]
        sp:px-[0]
        sp:gap-[1.5rem]
      ;
      &-inner {
        @apply
          flex
          flex-wrap
          justify-between
          gap-[2.2rem]
        ;
      }
    }
  }
  &-menu-list {
    @apply
      relative
      pl-[1.5rem]
      before:w-[.7rem]
      before:h-[.7rem]
      before:rounded-[100%]
      before:bg-[#FCEA00]
      before:absolute
      before:top-[1.2rem]
      before:left-[0]
    ;
    &__ttl {
      @apply
        p-service-dots-bg
        text-[1.6rem]
        relative
        [&>span]:relative
        [&>span]:bg-[white]
        [&>span]:pr-[1rem]
      ;
    }
    &__data {
      @apply
        flex
        justify-between
        relative
      ;
    }
  }
}