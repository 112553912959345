/* ==========================================================================
 text-align
========================================================================== */
.u-ta-l {
  text-align: left !important;
}

.u-ta-c {
  text-align: center !important;
}

.u-ta-r {
  text-align: right !important;
}

@media (--mobile) {
  .u-ta-l-sp {
    text-align: left !important;
  }

  .u-ta-c-sp {
    text-align: center !important;
  }

  .u-ta-r-sp {
    text-align: right !important;
  }
}
