@media (--large) {
  .c_hvr_border {
    @apply
      relative;

    &::after {
      @apply
        content-[""]
        absolute
        top-[50%]
        left-[50%]
        translate-x-[-50%]
        translate-y-[-50%]
        h-[calc(100%-0.3rem)]
        w-[calc(100%-0.3rem)]
        rounded-[1.5rem]
        border-[0.7rem]
        border-[#FCEA00]
        box-content
        opacity-0
        duration-[0.3s]
        pointer-events-none;
    }

    svg circle {
      @apply
        duration-[0.3s];
    }

    &:hover {
      &::after {
        opacity: 1;
      }

      svg circle {
        @apply
          fill-[#FCEA00];
      }
    }
  }

  .c_hvr_line {
    span {
      position: relative;
      transition: 0.5s;

      &::after {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        width: calc(100% + 1rem);
        height: 100%;
        content: "";
        background: #fcea00;
        border-radius: 0.5rem;
        transition: 0.5s transform;
        transform: translateX(-50%) scale(0, 1);
        transform-origin: right;
      }
    }

    &:hover {
      span {
        transform: scale(1.02);

        &::after {
          transform: translateX(-50%) scale(1, 1);
          transform-origin: left;
        }
      }
    }
  }
}
