@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Average+Sans&family=Zen+Kaku+Gothic+New:wght@500&display=swap&family=Outfit:wght@500&display=swap'");
@import url(normalize.css);
@import url(swiper/swiper-bundle.min.css);
.js-inview[data-inview="fade"] {
  opacity: 0;
  transition: opacity 1s; }
  .js-inview[data-inview="fade"].is-inview {
    opacity: 1; }

.js-inview[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transform: translateY(30px); }
  .js-inview[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview[data-inview="fade-left"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateX(-30px); }
  .js-inview[data-inview="fade-left"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateX(30px); }
  .js-inview[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview[data-inview="btn"] a::before,
.js-inview[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.5s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn"] i {
  opacity: 0;
  transition: opacity 0.5s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn"].is-inview a::before,
.js-inview[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="btn"].is-inview i {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="btn-border"] a::after,
.js-inview[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn-border"].is-inview a::after,
.js-inview[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="bg"]::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  transition: transform 0.4s;
  transform: scale(1, 1);
  transform-origin: right top; }

.js-inview[data-inview="bg"].is-inview::after {
  transform: scale(0, 1); }

.js-inview[data-inview="bg-white"]::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fff;
  transition: transform 0.4s;
  transform: scale(1, 1);
  transform-origin: right top; }

.js-inview[data-inview="bg-white"].is-inview::after {
  transform: scale(0, 1); }

.js-inview[data-inview="bg-scale"]::before {
  transition: transform 0.8s;
  transform: translate(-50%, -50%) scale(0.5);
  transform-origin: center center; }

.js-inview[data-inview="bg-scale"].is-inview::before {
  transform: translate(-50%, -50%) scale(1); }

.js-inview-photo[data-inview="scale-small"] {
  transition: transform 1s;
  transform: scale(1.5); }
  .is-inview .js-inview-photo[data-inview="scale-small"] {
    transform: scale(1); }

.js-inview-photo-img[data-inview="scale-small"] {
  transition: transform 1s;
  transform: translateX(-50%) scale(1.5); }
  .is-inview .js-inview-photo-img[data-inview="scale-small"] {
    transform: translateX(-50%) scale(1); }

.js-inview-eachItem[data-inview="fade"] {
  opacity: 0;
  transition: opacity 0.8s; }
  .js-inview-eachItem[data-inview="fade"].is-inview {
    opacity: 1; }

.js-inview-eachItem[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }
  .js-inview-eachItem[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview-eachItem[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(30px); }
  .js-inview-eachItem[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview-eachItem[data-inview="btn"] a::before,
.js-inview-eachItem[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-eachItem[data-inview="btn"].is-inview a::before,
.js-inview-eachItem[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview-eachItem[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="btn-border"] a::after,
.js-inview-eachItem[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-eachItem[data-inview="btn-border"].is-inview a::after,
.js-inview-eachItem[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview-eachItem[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="list-border"]::before {
  transition: transform 0.8s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="list-border"] span,
.js-inview-eachItem[data-inview="list-border"] figure,
.js-inview-eachItem[data-inview="list-border"] a {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }

.js-inview-eachItem[data-inview="list-border"] i {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(-20px) rotate(45deg); }

.js-inview-eachItem[data-inview="list-border"].is-inview::before {
  transform: scale(1); }

.js-inview-eachItem[data-inview="list-border"].is-inview span,
.js-inview-eachItem[data-inview="list-border"].is-inview figure,
.js-inview-eachItem[data-inview="list-border"].is-inview a {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="list-border"].is-inview i {
  opacity: 1;
  transform: translateX(-10px) rotate(45deg); }

.js-inview-eachItem-fast[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateY(10px); }
  .js-inview-eachItem-fast[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade"] {
  opacity: 0;
  transition: transform 0.8s; }
  .js-inview-eachItem-delay[data-inview="fade"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }
  .js-inview-eachItem-delay[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(30px); }
  .js-inview-eachItem-delay[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview-eachItem-delay[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-all[data-inview="btn"] a::before,
.js-inview-all[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-all[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-all[data-inview="btn"].is-inview a::before,
.js-inview-all[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview-all[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-all[data-inview="btn-border"] a::after,
.js-inview-all[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-all[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-all[data-inview="btn-border"].is-inview a::after,
.js-inview-all[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview-all[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

_:-ms-lang(x)::-ms-backdrop,
.js-inview-photo[data-inview="scale-small"] {
  transform: scale(1); }

_:-ms-lang(x)::-ms-backdrop,
.js-inview-photo-img[data-inview="scale-small"] {
  transform: translateX(-50%) scale(1); }

/**
 * Custom media queries
 * @example
 * @media (--mobile) {
 *    styles for mobile viewport
 * }
 */
/**
 * for mobile viewoport
 */
@custom-media --mobile screen and (max-width: 767px);
@custom-media --large screen and (min-width: 768px);
@custom-media --tablet screen and (max-width: 1023px) and (min-width: 768px);
@custom-media --vw_outside screen and (min-width: 1600px);
@custom-media --vw_inside screen and (min-width: 768px) and (max-width: 1599px);
/**
 * text ellipsis for block multiline
 */
/*
 unsupport IE, Edge, Fx

@mixin text-ellipsis-block($rows) {
  display: box;
  box-orient: vertical;
  line-clamp: $rows;
  overflow: hidden;
}
*/
/**
 * element size
 */
/**
 * position layout
 */
/**
 * images
 */
/**
* Variables
*/
/** extend */
@media (--large) {
  .ex-link {
    opacity: 1;
    transition: opacity 0.2s linear; }
    .ex-link:hover {
      opacity: .5; } }

/** http://bourbon.io/docs/#timing-functions */
/** example @include transition(all 5s $ease-in-circ);*/
/**
transition timing-function:
ease - cubic-bezier(0.25, 0.1, 0.25, 1.0) を指定したのと同じ（開始と完了を滑らかにする）（初期値）
linear - cubic-bezier(0.0, 0.0, 1.0, 1.0) を指定したのと同じ（一定）
ease-in - cubic-bezier(0.42, 0, 1.0, 1.0) を指定したのと同じ（ゆっくり始まる）
ease-out - cubic-bezier(0, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり終わる）
ease-in-out - cubic-bezier(0.42, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり始まってゆっくり終わる）
*/
/* == @import "../node_modules/normalize.css/normalize.css"; */
[v-cloak] {
  display: none; }

@tailwind base;
@tailwind components;
@tailwind utilities;
@media (--large) {
  html {
    font-size: 0.625vw; } }

@media (--mobile) {
  html {
    font-size: calc(10vw / 414 * 100); } }

html * {
  @apply font-jp tracking-[0.1em] leading-[1.8]; }

body {
  font-family: sans-serif;
  font-size: 1.6rem;
  font-feature-settings: "palt";
  letter-spacing: 0; }
  body::before {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    position: fixed;
    transition: background-color .5s,opacity .5s;
    pointer-events: none;
    opacity: 0;
    mix-blend-mode: multiply; }
  body.is-hum-active::before {
    background-color: #B1B1B1;
    z-index: 10;
    opacity: 1;
    pointer-events: visible; }

a {
  color: #000;
  text-decoration: none; }

*,
*::before,
*::after {
  box-sizing: border-box; }

h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
p {
  padding: 0;
  margin: 0; }

ul,
ol,
li {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

img {
  vertical-align: bottom; }

figure {
  padding: 0;
  margin: 0; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0; }

input[type="number"] {
  appearance: textfield; }

.l-main {
  line-height: 1.2; }

svg,
img {
  max-width: 100%; }

@media (--mobile) {
  html,
  body {
    width: 100%;
    height: 100%; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  dl,
  dt,
  dd,
  p {
    padding: 0;
    margin: 0; }
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%; } }

@media (--mobile) {
  .pc {
    display: none !important; } }

@media (--large) {
  [href*="tel:"] {
    pointer-events: none; }
  .sp {
    display: none !important; } }

@tailwind base;
@tailwind components;
@tailwind utilities;
.l-footer .f-hvr01 a {
  transition: 0.3s; }
  .l-footer .f-hvr01 a svg path {
    transition: 0.3s; }
  .l-footer .f-hvr01 a:hover {
    transform: scale(1.1); }
    .l-footer .f-hvr01 a:hover svg path {
      fill: #fcea00; }

/**
 * main.scss
 */
.c-breadcrumb {
  @apply relative
    z-[1]
    flex
    justify-end
    pr-[10rem]
    mt-[-2rem]

    sp:pr-0
    sp:mt-[1rem]; }
  .c-breadcrumb a {
    @apply relative
      pr-[1.5rem]
      mr-[1.5rem]; }
    .c-breadcrumb a::after {
      @apply absolute
        content-[""]
        h-[50%]
        w-[0.1rem]
        right-0
        top-[50%]
        translate-y-[-50%]
        rotate-[30deg]
        bg-[#000]; }

.c-btn-general {
  @apply [&>a]:border-[1px]
    [&>a]:rounded-[1rem]
    [&>a]:pl-[2.5rem]
    [&>a]:pt-[2.8rem]
    [&>a]:pb-[2.8rem]
    [&>a]:pr-[2rem]
    [&>a]:block
    [&>a]:mx-auto
    [&>a]:w-[28rem]

    [&>a>div]:relative

    [&>a>div>p]:text-[1.6rem]
    [&>a>div>p]:w-[calc(100%-5.2rem)]

    [&>a>div>svg]:w-[4.4rem]

    [&>a>div>svg]:absolute
    [&>a>div>svg]:top-[50%]
    [&>a>div>svg]:right-0
    [&>a>div>svg]:translate-y-[-50%]; }

.c-btn-normal {
  @apply [&>a]:inline-flex
    [&>a]:items-center
    [&>a]:font-en

    [&>a>span]:font-en

    [&>a>div]:bg-[#fff]
    [&>a>div]:w-[4.1rem]
    [&>a>div]:h-[4.1rem]
    [&>a>div]:flex
    [&>a>div]:items-center
    [&>a>div]:justify-center
    [&>a>div]:border-[1px]
    [&>a>div]:border-[#231815]
    [&>a>div]:ml-[1rem]
    [&>a>div]:rounded-[9999px]
    [&>a>div]:overflow-hidden
    [&>a>div]:relative

    [&>a>div]:after:content-[""]
    [&>a>div]:after:absolute
    [&>a>div]:after:left-0
    [&>a>div]:after:top-0
    [&>a>div]:after:w-[100%]
    [&>a>div]:after:h-[100%]
    [&>a>div]:after:bg-[#FCEA00]
    [&>a>div]:after:translate-y-[100%]
    [&>a>div]:after:duration-[0.5s]
    [&>a>div]:after:pointer-events-none

    [&>a>div>svg]:w-[0.5rem]
    [&>a>div>svg]:relative
    [&>a>div>svg]:z-[1]; }
  .c-btn-normal a:hover > div {
    @apply after:translate-y-[0%]; }

.c-heading_type01 {
  @apply relative
      mt-[10.4rem]
      ml-[0]
      text-[4.2rem]
      leading-[1.4]

      sp:mt-[6rem]
      sp:text-[3.2rem]; }
  .c-heading_type01::after {
    @apply absolute
        left-[-0.4rem]
        top-[0.9rem]
        h-[1.5rem]
        w-[1.5rem]
        rounded-[9999px]
        bg-[#FCEA00]
        content-[""]
        z-[-1]

        sp:top-[0.5rem]
        sp:left-[-0.6rem]; }
  .c-heading_type01 small {
    @apply block
        text-[1.8rem]
        text-[#B5B5B6]; }

.c-heading_type02 {
  @apply flex
      pl-[2rem]
      text-[1.8rem]
      relative; }
  .c-heading_type02::after {
    @apply content-[""]
        absolute
        h-[1rem]
        w-[1rem]
        bg-[#FCEA00]
        left-0
        top-[0.9rem]
        rounded-[9999px]; }
  .c-heading_type02 span {
    @apply leading-[1.5]; }

.c-heading-num {
  @apply text-[2rem]
    pl-[4rem]
    mt-[5.5rem]
    mb-[2.5rem]
    relative
    [counter-increment:headingCount]

    sp:text-[1.8rem]

    before:[content:counter(headingCount,decimal-leading-zero)]
    before:font-en
    before:text-[#FCEA00]
    before:text-[2.8rem]
    before:absolute
    before:top-[50%]
    before:left-[0]
    before:translate-y-[-50%]; }

@media (--large) {
  .c_hvr_border {
    @apply relative; }
    .c_hvr_border::after {
      @apply content-[""]
        absolute
        top-[50%]
        left-[50%]
        translate-x-[-50%]
        translate-y-[-50%]
        h-[calc(100%-0.3rem)]
        w-[calc(100%-0.3rem)]
        rounded-[1.5rem]
        border-[0.7rem]
        border-[#FCEA00]
        box-content
        opacity-0
        duration-[0.3s]
        pointer-events-none; }
    .c_hvr_border svg circle {
      @apply duration-[0.3s]; }
    .c_hvr_border:hover::after {
      opacity: 1; }
    .c_hvr_border:hover svg circle {
      @apply fill-[#FCEA00]; }
  .c_hvr_line span {
    position: relative;
    transition: 0.5s; }
    .c_hvr_line span::after {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      width: calc(100% + 1rem);
      height: 100%;
      content: "";
      background: #fcea00;
      border-radius: 0.5rem;
      transition: 0.5s transform;
      transform: translateX(-50%) scale(0, 1);
      transform-origin: right; }
  .c_hvr_line:hover span {
    transform: scale(1.02); }
    .c_hvr_line:hover span::after {
      transform: translateX(-50%) scale(1, 1);
      transform-origin: left; } }

.c-inner {
  padding: 0 7rem; }
  @media (--mobile) {
    .c-inner {
      padding: 0 3rem; } }

.c-row {
  display: flex; }

.c-google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden; }

.c-google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

/**
* wordpress
* WYSIWYG compatchble style
*/
.c-wp-post_article strong {
  font-weight: bold; }

.c-wp-post_article em {
  font-style: italic; }

.c-wp-post_article ul {
  margin-left: 1em;
  display: block;
  list-style-type: disc !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article ol {
  display: block;
  list-style-type: decimal !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article li {
  display: list-item;
  text-align: match-parent; }

.c-wp-post_article img {
  width: auto; }

.c-wp-post_article .alignleft {
  display: inline;
  float: left; }

.c-wp-post_article .alignright {
  display: inline;
  float: right; }

.c-wp-post_article .aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.c-wp-post_article blockquote.alignleft,
.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article img.alignleft {
  margin: 0.4em 1.6em 1.6em 0; }

.c-wp-post_article blockquote.alignright,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article img.alignright {
  margin: 0.4em 0 1.6em 1.6em; }

.c-wp-post_article blockquote.aligncenter,
.c-wp-post_article .wp-caption.aligncenter,
.c-wp-post_article img.aligncenter {
  clear: both;
  margin-top: 0.4em;
  margin-bottom: 1.6em; }

.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article .wp-caption.aligncenter {
  margin-bottom: 1.2em; }

/**
 * clearfix micro
 */
.clearfix:after {
  content: "";
  clear: both;
  display: block; }

/**
 * text ellipsis., text...
 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u-hide {
  display: none; }

.u-click {
  cursor: pointer; }

@media (--large) {
  .for-mobile {
    display: none; } }

@media (--mobile) {
  .for-large {
    display: none; } }

/* ==========================================================================
 font-size
========================================================================== */
/* font-size */
.u-fs-0 {
  font-size: 0rem !important; }

.u-fs-1 {
  font-size: 0.1rem !important; }

.u-fs-2 {
  font-size: 0.2rem !important; }

.u-fs-3 {
  font-size: 0.3rem !important; }

.u-fs-4 {
  font-size: 0.4rem !important; }

.u-fs-5 {
  font-size: 0.5rem !important; }

.u-fs-6 {
  font-size: 0.6rem !important; }

.u-fs-7 {
  font-size: 0.7rem !important; }

.u-fs-8 {
  font-size: 0.8rem !important; }

.u-fs-9 {
  font-size: 0.9rem !important; }

.u-fs-10 {
  font-size: 1rem !important; }

.u-fs-11 {
  font-size: 1.1rem !important; }

.u-fs-12 {
  font-size: 1.2rem !important; }

.u-fs-13 {
  font-size: 1.3rem !important; }

.u-fs-14 {
  font-size: 1.4rem !important; }

.u-fs-15 {
  font-size: 1.5rem !important; }

.u-fs-16 {
  font-size: 1.6rem !important; }

.u-fs-17 {
  font-size: 1.7rem !important; }

.u-fs-18 {
  font-size: 1.8rem !important; }

.u-fs-19 {
  font-size: 1.9rem !important; }

.u-fs-20 {
  font-size: 2rem !important; }

.u-fs-21 {
  font-size: 2.1rem !important; }

.u-fs-22 {
  font-size: 2.2rem !important; }

.u-fs-23 {
  font-size: 2.3rem !important; }

.u-fs-24 {
  font-size: 2.4rem !important; }

.u-fs-25 {
  font-size: 2.5rem !important; }

.u-fs-26 {
  font-size: 2.6rem !important; }

.u-fs-27 {
  font-size: 2.7rem !important; }

.u-fs-28 {
  font-size: 2.8rem !important; }

.u-fs-29 {
  font-size: 2.9rem !important; }

.u-fs-30 {
  font-size: 3rem !important; }

.u-fs-31 {
  font-size: 3.1rem !important; }

.u-fs-32 {
  font-size: 3.2rem !important; }

.u-fs-33 {
  font-size: 3.3rem !important; }

.u-fs-34 {
  font-size: 3.4rem !important; }

.u-fs-35 {
  font-size: 3.5rem !important; }

.u-fs-36 {
  font-size: 3.6rem !important; }

.u-fs-37 {
  font-size: 3.7rem !important; }

.u-fs-38 {
  font-size: 3.8rem !important; }

.u-fs-39 {
  font-size: 3.9rem !important; }

.u-fs-40 {
  font-size: 4rem !important; }

.u-fs-41 {
  font-size: 4.1rem !important; }

.u-fs-42 {
  font-size: 4.2rem !important; }

.u-fs-43 {
  font-size: 4.3rem !important; }

.u-fs-44 {
  font-size: 4.4rem !important; }

.u-fs-45 {
  font-size: 4.5rem !important; }

.u-fs-46 {
  font-size: 4.6rem !important; }

.u-fs-47 {
  font-size: 4.7rem !important; }

.u-fs-48 {
  font-size: 4.8rem !important; }

.u-fs-49 {
  font-size: 4.9rem !important; }

@media (--mobile) {
  /* font-size */
  .u-fs-sp-0 {
    font-size: 0rem !important; }
  .u-fs-sp-1 {
    font-size: 0.1rem !important; }
  .u-fs-sp-2 {
    font-size: 0.2rem !important; }
  .u-fs-sp-3 {
    font-size: 0.3rem !important; }
  .u-fs-sp-4 {
    font-size: 0.4rem !important; }
  .u-fs-sp-5 {
    font-size: 0.5rem !important; }
  .u-fs-sp-6 {
    font-size: 0.6rem !important; }
  .u-fs-sp-7 {
    font-size: 0.7rem !important; }
  .u-fs-sp-8 {
    font-size: 0.8rem !important; }
  .u-fs-sp-9 {
    font-size: 0.9rem !important; }
  .u-fs-sp-10 {
    font-size: 1rem !important; }
  .u-fs-sp-11 {
    font-size: 1.1rem !important; }
  .u-fs-sp-12 {
    font-size: 1.2rem !important; }
  .u-fs-sp-13 {
    font-size: 1.3rem !important; }
  .u-fs-sp-14 {
    font-size: 1.4rem !important; }
  .u-fs-sp-15 {
    font-size: 1.5rem !important; }
  .u-fs-sp-16 {
    font-size: 1.6rem !important; }
  .u-fs-sp-17 {
    font-size: 1.7rem !important; }
  .u-fs-sp-18 {
    font-size: 1.8rem !important; }
  .u-fs-sp-19 {
    font-size: 1.9rem !important; }
  .u-fs-sp-20 {
    font-size: 2rem !important; }
  .u-fs-sp-21 {
    font-size: 2.1rem !important; }
  .u-fs-sp-22 {
    font-size: 2.2rem !important; }
  .u-fs-sp-23 {
    font-size: 2.3rem !important; }
  .u-fs-sp-24 {
    font-size: 2.4rem !important; }
  .u-fs-sp-25 {
    font-size: 2.5rem !important; }
  .u-fs-sp-26 {
    font-size: 2.6rem !important; }
  .u-fs-sp-27 {
    font-size: 2.7rem !important; }
  .u-fs-sp-28 {
    font-size: 2.8rem !important; }
  .u-fs-sp-29 {
    font-size: 2.9rem !important; }
  .u-fs-sp-30 {
    font-size: 3rem !important; }
  .u-fs-sp-31 {
    font-size: 3.1rem !important; }
  .u-fs-sp-32 {
    font-size: 3.2rem !important; }
  .u-fs-sp-33 {
    font-size: 3.3rem !important; }
  .u-fs-sp-34 {
    font-size: 3.4rem !important; }
  .u-fs-sp-35 {
    font-size: 3.5rem !important; }
  .u-fs-sp-36 {
    font-size: 3.6rem !important; }
  .u-fs-sp-37 {
    font-size: 3.7rem !important; }
  .u-fs-sp-38 {
    font-size: 3.8rem !important; }
  .u-fs-sp-39 {
    font-size: 3.9rem !important; }
  .u-fs-sp-40 {
    font-size: 4rem !important; }
  .u-fs-sp-41 {
    font-size: 4.1rem !important; }
  .u-fs-sp-42 {
    font-size: 4.2rem !important; }
  .u-fs-sp-43 {
    font-size: 4.3rem !important; }
  .u-fs-sp-44 {
    font-size: 4.4rem !important; }
  .u-fs-sp-45 {
    font-size: 4.5rem !important; }
  .u-fs-sp-46 {
    font-size: 4.6rem !important; }
  .u-fs-sp-47 {
    font-size: 4.7rem !important; }
  .u-fs-sp-48 {
    font-size: 4.8rem !important; }
  .u-fs-sp-49 {
    font-size: 4.9rem !important; } }

/* ==========================================================================
 font-weigth
========================================================================== */
/* font-weigth */
.u-fw-l {
  font-weight: lighter !important; }

.u-fw-n {
  font-weight: normal !important; }

.u-fw-b {
  font-weight: bold !important; }

@media (--mobile) {
  /* font-weigth */
  .u-fw-sp-l {
    font-weight: lighter !important; }
  .u-fw-sp-n {
    font-weight: normal !important; }
  .u-fw-sp-b {
    font-weight: bold !important; } }

/**
 * clearfix micro
 */
.clearfix:after {
  content: "";
  clear: both;
  display: block; }

/**
 * text ellipsis., text...
 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u-hide {
  display: none; }

.u-click {
  cursor: pointer; }

@media (--large) {
  .for-mobile {
    display: none; } }

@media (--mobile) {
  .for-large {
    display: none; } }

/* ==========================================================================
 line-height
========================================================================== */
/* line-height */
.u-lh-0 {
  line-height: 0 !important; }

.u-lh-1 {
  line-height: 0.1 !important; }

.u-lh-2 {
  line-height: 0.2 !important; }

.u-lh-3 {
  line-height: 0.3 !important; }

.u-lh-4 {
  line-height: 0.4 !important; }

.u-lh-5 {
  line-height: 0.5 !important; }

.u-lh-6 {
  line-height: 0.6 !important; }

.u-lh-7 {
  line-height: 0.7 !important; }

.u-lh-8 {
  line-height: 0.8 !important; }

.u-lh-9 {
  line-height: 0.9 !important; }

.u-lh-10 {
  line-height: 1 !important; }

.u-lh-11 {
  line-height: 1.1 !important; }

.u-lh-12 {
  line-height: 1.2 !important; }

.u-lh-13 {
  line-height: 1.3 !important; }

.u-lh-14 {
  line-height: 1.4 !important; }

.u-lh-15 {
  line-height: 1.5 !important; }

.u-lh-16 {
  line-height: 1.6 !important; }

.u-lh-17 {
  line-height: 1.7 !important; }

.u-lh-18 {
  line-height: 1.8 !important; }

.u-lh-19 {
  line-height: 1.9 !important; }

.u-lh-20 {
  line-height: 2 !important; }

.u-lh-21 {
  line-height: 2.1 !important; }

.u-lh-22 {
  line-height: 2.2 !important; }

.u-lh-23 {
  line-height: 2.3 !important; }

.u-lh-24 {
  line-height: 2.4 !important; }

.u-lh-25 {
  line-height: 2.5 !important; }

.u-lh-26 {
  line-height: 2.6 !important; }

.u-lh-27 {
  line-height: 2.7 !important; }

.u-lh-28 {
  line-height: 2.8 !important; }

.u-lh-29 {
  line-height: 2.9 !important; }

.u-lh-30 {
  line-height: 3 !important; }

.u-lh-31 {
  line-height: 3.1 !important; }

.u-lh-32 {
  line-height: 3.2 !important; }

.u-lh-33 {
  line-height: 3.3 !important; }

.u-lh-34 {
  line-height: 3.4 !important; }

.u-lh-35 {
  line-height: 3.5 !important; }

.u-lh-36 {
  line-height: 3.6 !important; }

.u-lh-37 {
  line-height: 3.7 !important; }

.u-lh-38 {
  line-height: 3.8 !important; }

.u-lh-39 {
  line-height: 3.9 !important; }

.u-lh-40 {
  line-height: 4 !important; }

.u-lh-41 {
  line-height: 4.1 !important; }

.u-lh-42 {
  line-height: 4.2 !important; }

.u-lh-43 {
  line-height: 4.3 !important; }

.u-lh-44 {
  line-height: 4.4 !important; }

.u-lh-45 {
  line-height: 4.5 !important; }

.u-lh-46 {
  line-height: 4.6 !important; }

.u-lh-47 {
  line-height: 4.7 !important; }

.u-lh-48 {
  line-height: 4.8 !important; }

.u-lh-49 {
  line-height: 4.9 !important; }

@media (--mobile) {
  /* line-height */
  .u-lh-sp-0 {
    line-height: 0 !important; }
  .u-lh-sp-1 {
    line-height: 0.1 !important; }
  .u-lh-sp-2 {
    line-height: 0.2 !important; }
  .u-lh-sp-3 {
    line-height: 0.3 !important; }
  .u-lh-sp-4 {
    line-height: 0.4 !important; }
  .u-lh-sp-5 {
    line-height: 0.5 !important; }
  .u-lh-sp-6 {
    line-height: 0.6 !important; }
  .u-lh-sp-7 {
    line-height: 0.7 !important; }
  .u-lh-sp-8 {
    line-height: 0.8 !important; }
  .u-lh-sp-9 {
    line-height: 0.9 !important; }
  .u-lh-sp-10 {
    line-height: 1 !important; }
  .u-lh-sp-11 {
    line-height: 1.1 !important; }
  .u-lh-sp-12 {
    line-height: 1.2 !important; }
  .u-lh-sp-13 {
    line-height: 1.3 !important; }
  .u-lh-sp-14 {
    line-height: 1.4 !important; }
  .u-lh-sp-15 {
    line-height: 1.5 !important; }
  .u-lh-sp-16 {
    line-height: 1.6 !important; }
  .u-lh-sp-17 {
    line-height: 1.7 !important; }
  .u-lh-sp-18 {
    line-height: 1.8 !important; }
  .u-lh-sp-19 {
    line-height: 1.9 !important; }
  .u-lh-sp-20 {
    line-height: 2 !important; }
  .u-lh-sp-21 {
    line-height: 2.1 !important; }
  .u-lh-sp-22 {
    line-height: 2.2 !important; }
  .u-lh-sp-23 {
    line-height: 2.3 !important; }
  .u-lh-sp-24 {
    line-height: 2.4 !important; }
  .u-lh-sp-25 {
    line-height: 2.5 !important; }
  .u-lh-sp-26 {
    line-height: 2.6 !important; }
  .u-lh-sp-27 {
    line-height: 2.7 !important; }
  .u-lh-sp-28 {
    line-height: 2.8 !important; }
  .u-lh-sp-29 {
    line-height: 2.9 !important; }
  .u-lh-sp-30 {
    line-height: 3 !important; }
  .u-lh-sp-31 {
    line-height: 3.1 !important; }
  .u-lh-sp-32 {
    line-height: 3.2 !important; }
  .u-lh-sp-33 {
    line-height: 3.3 !important; }
  .u-lh-sp-34 {
    line-height: 3.4 !important; }
  .u-lh-sp-35 {
    line-height: 3.5 !important; }
  .u-lh-sp-36 {
    line-height: 3.6 !important; }
  .u-lh-sp-37 {
    line-height: 3.7 !important; }
  .u-lh-sp-38 {
    line-height: 3.8 !important; }
  .u-lh-sp-39 {
    line-height: 3.9 !important; }
  .u-lh-sp-40 {
    line-height: 4 !important; }
  .u-lh-sp-41 {
    line-height: 4.1 !important; }
  .u-lh-sp-42 {
    line-height: 4.2 !important; }
  .u-lh-sp-43 {
    line-height: 4.3 !important; }
  .u-lh-sp-44 {
    line-height: 4.4 !important; }
  .u-lh-sp-45 {
    line-height: 4.5 !important; }
  .u-lh-sp-46 {
    line-height: 4.6 !important; }
  .u-lh-sp-47 {
    line-height: 4.7 !important; }
  .u-lh-sp-48 {
    line-height: 4.8 !important; }
  .u-lh-sp-49 {
    line-height: 4.9 !important; } }

/* ==========================================================================
 margin
========================================================================== */
.u-m-c {
  margin-right: auto !important;
  margin-left: auto !important; }

.u-m-r {
  margin-right: auto !important;
  margin-left: 0 !important; }

/* Top margin */
.u-mt-0 {
  margin-top: 0rem !important; }

.u-mt-1 {
  margin-top: 1rem !important; }

.u-mt-2 {
  margin-top: 2rem !important; }

.u-mt-3 {
  margin-top: 3rem !important; }

.u-mt-4 {
  margin-top: 4rem !important; }

.u-mt-5 {
  margin-top: 5rem !important; }

.u-mt-6 {
  margin-top: 6rem !important; }

.u-mt-7 {
  margin-top: 7rem !important; }

.u-mt-8 {
  margin-top: 8rem !important; }

.u-mt-9 {
  margin-top: 9rem !important; }

.u-mt-10 {
  margin-top: 10rem !important; }

.u-mt-11 {
  margin-top: 11rem !important; }

.u-mt-12 {
  margin-top: 12rem !important; }

.u-mt-13 {
  margin-top: 13rem !important; }

.u-mt-14 {
  margin-top: 14rem !important; }

.u-mt-15 {
  margin-top: 15rem !important; }

.u-mt-16 {
  margin-top: 16rem !important; }

.u-mt-17 {
  margin-top: 17rem !important; }

.u-mt-18 {
  margin-top: 18rem !important; }

.u-mt-19 {
  margin-top: 19rem !important; }

.u-mt-20 {
  margin-top: 20rem !important; }

.u-mt-21 {
  margin-top: 21rem !important; }

.u-mt-22 {
  margin-top: 22rem !important; }

.u-mt-23 {
  margin-top: 23rem !important; }

.u-mt-24 {
  margin-top: 24rem !important; }

.u-mt-25 {
  margin-top: 25rem !important; }

/* Right margin */
.u-mr-0 {
  margin-right: 0rem !important; }

.u-mr-1 {
  margin-right: 1rem !important; }

.u-mr-2 {
  margin-right: 2rem !important; }

.u-mr-3 {
  margin-right: 3rem !important; }

.u-mr-4 {
  margin-right: 4rem !important; }

.u-mr-5 {
  margin-right: 5rem !important; }

.u-mr-6 {
  margin-right: 6rem !important; }

.u-mr-7 {
  margin-right: 7rem !important; }

.u-mr-8 {
  margin-right: 8rem !important; }

.u-mr-9 {
  margin-right: 9rem !important; }

.u-mr-10 {
  margin-right: 10rem !important; }

.u-mr-11 {
  margin-right: 11rem !important; }

.u-mr-12 {
  margin-right: 12rem !important; }

.u-mr-13 {
  margin-right: 13rem !important; }

.u-mr-14 {
  margin-right: 14rem !important; }

.u-mr-15 {
  margin-right: 15rem !important; }

.u-mr-16 {
  margin-right: 16rem !important; }

.u-mr-17 {
  margin-right: 17rem !important; }

.u-mr-18 {
  margin-right: 18rem !important; }

.u-mr-19 {
  margin-right: 19rem !important; }

.u-mr-20 {
  margin-right: 20rem !important; }

.u-mr-21 {
  margin-right: 21rem !important; }

.u-mr-22 {
  margin-right: 22rem !important; }

.u-mr-23 {
  margin-right: 23rem !important; }

.u-mr-24 {
  margin-right: 24rem !important; }

.u-mr-25 {
  margin-right: 25rem !important; }

/* Bottom margin */
.u-mb-0 {
  margin-bottom: 0rem !important; }

.u-mb-1 {
  margin-bottom: 1rem !important; }

.u-mb-2 {
  margin-bottom: 2rem !important; }

.u-mb-3 {
  margin-bottom: 3rem !important; }

.u-mb-4 {
  margin-bottom: 4rem !important; }

.u-mb-5 {
  margin-bottom: 5rem !important; }

.u-mb-6 {
  margin-bottom: 6rem !important; }

.u-mb-7 {
  margin-bottom: 7rem !important; }

.u-mb-8 {
  margin-bottom: 8rem !important; }

.u-mb-9 {
  margin-bottom: 9rem !important; }

.u-mb-10 {
  margin-bottom: 10rem !important; }

.u-mb-11 {
  margin-bottom: 11rem !important; }

.u-mb-12 {
  margin-bottom: 12rem !important; }

.u-mb-13 {
  margin-bottom: 13rem !important; }

.u-mb-14 {
  margin-bottom: 14rem !important; }

.u-mb-15 {
  margin-bottom: 15rem !important; }

.u-mb-16 {
  margin-bottom: 16rem !important; }

.u-mb-17 {
  margin-bottom: 17rem !important; }

.u-mb-18 {
  margin-bottom: 18rem !important; }

.u-mb-19 {
  margin-bottom: 19rem !important; }

.u-mb-20 {
  margin-bottom: 20rem !important; }

.u-mb-21 {
  margin-bottom: 21rem !important; }

.u-mb-22 {
  margin-bottom: 22rem !important; }

.u-mb-23 {
  margin-bottom: 23rem !important; }

.u-mb-24 {
  margin-bottom: 24rem !important; }

.u-mb-25 {
  margin-bottom: 25rem !important; }

/* Left margin */
.u-ml-0 {
  margin-left: 0rem !important; }

.u-ml-1 {
  margin-left: 1rem !important; }

.u-ml-2 {
  margin-left: 2rem !important; }

.u-ml-3 {
  margin-left: 3rem !important; }

.u-ml-4 {
  margin-left: 4rem !important; }

.u-ml-5 {
  margin-left: 5rem !important; }

.u-ml-6 {
  margin-left: 6rem !important; }

.u-ml-7 {
  margin-left: 7rem !important; }

.u-ml-8 {
  margin-left: 8rem !important; }

.u-ml-9 {
  margin-left: 9rem !important; }

.u-ml-10 {
  margin-left: 10rem !important; }

.u-ml-11 {
  margin-left: 11rem !important; }

.u-ml-12 {
  margin-left: 12rem !important; }

.u-ml-13 {
  margin-left: 13rem !important; }

.u-ml-14 {
  margin-left: 14rem !important; }

.u-ml-15 {
  margin-left: 15rem !important; }

.u-ml-16 {
  margin-left: 16rem !important; }

.u-ml-17 {
  margin-left: 17rem !important; }

.u-ml-18 {
  margin-left: 18rem !important; }

.u-ml-19 {
  margin-left: 19rem !important; }

.u-ml-20 {
  margin-left: 20rem !important; }

.u-ml-21 {
  margin-left: 21rem !important; }

.u-ml-22 {
  margin-left: 22rem !important; }

.u-ml-23 {
  margin-left: 23rem !important; }

.u-ml-24 {
  margin-left: 24rem !important; }

.u-ml-25 {
  margin-left: 25rem !important; }

@media (--mobile) {
  .u-m-sp-c {
    margin-right: auto !important;
    margin-left: auto !important; }
  .u-m-sp-r {
    margin-right: auto !important;
    margin-left: 0 !important; }
  /* Top margin */
  .u-mt-sp-0 {
    margin-top: 0rem !important; }
  .u-mt-sp-1 {
    margin-top: 1rem !important; }
  .u-mt-sp-2 {
    margin-top: 2rem !important; }
  .u-mt-sp-3 {
    margin-top: 3rem !important; }
  .u-mt-sp-4 {
    margin-top: 4rem !important; }
  .u-mt-sp-5 {
    margin-top: 5rem !important; }
  .u-mt-sp-6 {
    margin-top: 6rem !important; }
  .u-mt-sp-7 {
    margin-top: 7rem !important; }
  .u-mt-sp-8 {
    margin-top: 8rem !important; }
  .u-mt-sp-9 {
    margin-top: 9rem !important; }
  .u-mt-sp-10 {
    margin-top: 10rem !important; }
  .u-mt-sp-11 {
    margin-top: 11rem !important; }
  .u-mt-sp-12 {
    margin-top: 12rem !important; }
  .u-mt-sp-13 {
    margin-top: 13rem !important; }
  .u-mt-sp-14 {
    margin-top: 14rem !important; }
  .u-mt-sp-15 {
    margin-top: 15rem !important; }
  .u-mt-sp-16 {
    margin-top: 16rem !important; }
  .u-mt-sp-17 {
    margin-top: 17rem !important; }
  .u-mt-sp-18 {
    margin-top: 18rem !important; }
  .u-mt-sp-19 {
    margin-top: 19rem !important; }
  .u-mt-sp-20 {
    margin-top: 20rem !important; }
  .u-mt-sp-21 {
    margin-top: 21rem !important; }
  .u-mt-sp-22 {
    margin-top: 22rem !important; }
  .u-mt-sp-23 {
    margin-top: 23rem !important; }
  .u-mt-sp-24 {
    margin-top: 24rem !important; }
  .u-mt-sp-25 {
    margin-top: 25rem !important; }
  /* Right margin */
  .u-mr-sp-0 {
    margin-right: 0rem !important; }
  .u-mr-sp-1 {
    margin-right: 1rem !important; }
  .u-mr-sp-2 {
    margin-right: 2rem !important; }
  .u-mr-sp-3 {
    margin-right: 3rem !important; }
  .u-mr-sp-4 {
    margin-right: 4rem !important; }
  .u-mr-sp-5 {
    margin-right: 5rem !important; }
  .u-mr-sp-6 {
    margin-right: 6rem !important; }
  .u-mr-sp-7 {
    margin-right: 7rem !important; }
  .u-mr-sp-8 {
    margin-right: 8rem !important; }
  .u-mr-sp-9 {
    margin-right: 9rem !important; }
  .u-mr-sp-10 {
    margin-right: 10rem !important; }
  .u-mr-sp-11 {
    margin-right: 11rem !important; }
  .u-mr-sp-12 {
    margin-right: 12rem !important; }
  .u-mr-sp-13 {
    margin-right: 13rem !important; }
  .u-mr-sp-14 {
    margin-right: 14rem !important; }
  .u-mr-sp-15 {
    margin-right: 15rem !important; }
  .u-mr-sp-16 {
    margin-right: 16rem !important; }
  .u-mr-sp-17 {
    margin-right: 17rem !important; }
  .u-mr-sp-18 {
    margin-right: 18rem !important; }
  .u-mr-sp-19 {
    margin-right: 19rem !important; }
  .u-mr-sp-20 {
    margin-right: 20rem !important; }
  .u-mr-sp-21 {
    margin-right: 21rem !important; }
  .u-mr-sp-22 {
    margin-right: 22rem !important; }
  .u-mr-sp-23 {
    margin-right: 23rem !important; }
  .u-mr-sp-24 {
    margin-right: 24rem !important; }
  .u-mr-sp-25 {
    margin-right: 25rem !important; }
  /* Bottom margin */
  .u-mb-sp-0 {
    margin-bottom: 0rem !important; }
  .u-mb-sp-1 {
    margin-bottom: 1rem !important; }
  .u-mb-sp-2 {
    margin-bottom: 2rem !important; }
  .u-mb-sp-3 {
    margin-bottom: 3rem !important; }
  .u-mb-sp-4 {
    margin-bottom: 4rem !important; }
  .u-mb-sp-5 {
    margin-bottom: 5rem !important; }
  .u-mb-sp-6 {
    margin-bottom: 6rem !important; }
  .u-mb-sp-7 {
    margin-bottom: 7rem !important; }
  .u-mb-sp-8 {
    margin-bottom: 8rem !important; }
  .u-mb-sp-9 {
    margin-bottom: 9rem !important; }
  .u-mb-sp-10 {
    margin-bottom: 10rem !important; }
  .u-mb-sp-11 {
    margin-bottom: 11rem !important; }
  .u-mb-sp-12 {
    margin-bottom: 12rem !important; }
  .u-mb-sp-13 {
    margin-bottom: 13rem !important; }
  .u-mb-sp-14 {
    margin-bottom: 14rem !important; }
  .u-mb-sp-15 {
    margin-bottom: 15rem !important; }
  .u-mb-sp-16 {
    margin-bottom: 16rem !important; }
  .u-mb-sp-17 {
    margin-bottom: 17rem !important; }
  .u-mb-sp-18 {
    margin-bottom: 18rem !important; }
  .u-mb-sp-19 {
    margin-bottom: 19rem !important; }
  .u-mb-sp-20 {
    margin-bottom: 20rem !important; }
  .u-mb-sp-21 {
    margin-bottom: 21rem !important; }
  .u-mb-sp-22 {
    margin-bottom: 22rem !important; }
  .u-mb-sp-23 {
    margin-bottom: 23rem !important; }
  .u-mb-sp-24 {
    margin-bottom: 24rem !important; }
  .u-mb-sp-25 {
    margin-bottom: 25rem !important; }
  /* Left margin */
  .u-ml-sp-0 {
    margin-left: 0rem !important; }
  .u-ml-sp-1 {
    margin-left: 1rem !important; }
  .u-ml-sp-2 {
    margin-left: 2rem !important; }
  .u-ml-sp-3 {
    margin-left: 3rem !important; }
  .u-ml-sp-4 {
    margin-left: 4rem !important; }
  .u-ml-sp-5 {
    margin-left: 5rem !important; }
  .u-ml-sp-6 {
    margin-left: 6rem !important; }
  .u-ml-sp-7 {
    margin-left: 7rem !important; }
  .u-ml-sp-8 {
    margin-left: 8rem !important; }
  .u-ml-sp-9 {
    margin-left: 9rem !important; }
  .u-ml-sp-10 {
    margin-left: 10rem !important; }
  .u-ml-sp-11 {
    margin-left: 11rem !important; }
  .u-ml-sp-12 {
    margin-left: 12rem !important; }
  .u-ml-sp-13 {
    margin-left: 13rem !important; }
  .u-ml-sp-14 {
    margin-left: 14rem !important; }
  .u-ml-sp-15 {
    margin-left: 15rem !important; }
  .u-ml-sp-16 {
    margin-left: 16rem !important; }
  .u-ml-sp-17 {
    margin-left: 17rem !important; }
  .u-ml-sp-18 {
    margin-left: 18rem !important; }
  .u-ml-sp-19 {
    margin-left: 19rem !important; }
  .u-ml-sp-20 {
    margin-left: 20rem !important; }
  .u-ml-sp-21 {
    margin-left: 21rem !important; }
  .u-ml-sp-22 {
    margin-left: 22rem !important; }
  .u-ml-sp-23 {
    margin-left: 23rem !important; }
  .u-ml-sp-24 {
    margin-left: 24rem !important; }
  .u-ml-sp-25 {
    margin-left: 25rem !important; } }

.u-objectFit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; }

/* ==========================================================================
 padding
========================================================================== */
/* Top padding */
.u-pt-0 {
  padding-top: 0rem !important; }

.u-pt-1 {
  padding-top: 1rem !important; }

.u-pt-2 {
  padding-top: 2rem !important; }

.u-pt-3 {
  padding-top: 3rem !important; }

.u-pt-4 {
  padding-top: 4rem !important; }

.u-pt-5 {
  padding-top: 5rem !important; }

.u-pt-6 {
  padding-top: 6rem !important; }

.u-pt-7 {
  padding-top: 7rem !important; }

.u-pt-8 {
  padding-top: 8rem !important; }

.u-pt-9 {
  padding-top: 9rem !important; }

.u-pt-10 {
  padding-top: 10rem !important; }

.u-pt-11 {
  padding-top: 11rem !important; }

.u-pt-12 {
  padding-top: 12rem !important; }

.u-pt-13 {
  padding-top: 13rem !important; }

.u-pt-14 {
  padding-top: 14rem !important; }

.u-pt-15 {
  padding-top: 15rem !important; }

.u-pt-16 {
  padding-top: 16rem !important; }

.u-pt-17 {
  padding-top: 17rem !important; }

.u-pt-18 {
  padding-top: 18rem !important; }

.u-pt-19 {
  padding-top: 19rem !important; }

.u-pt-20 {
  padding-top: 20rem !important; }

.u-pt-21 {
  padding-top: 21rem !important; }

.u-pt-22 {
  padding-top: 22rem !important; }

.u-pt-23 {
  padding-top: 23rem !important; }

.u-pt-24 {
  padding-top: 24rem !important; }

.u-pt-25 {
  padding-top: 25rem !important; }

/* Right padding */
.u-pr-0 {
  padding-right: 0rem !important; }

.u-pr-1 {
  padding-right: 1rem !important; }

.u-pr-2 {
  padding-right: 2rem !important; }

.u-pr-3 {
  padding-right: 3rem !important; }

.u-pr-4 {
  padding-right: 4rem !important; }

.u-pr-5 {
  padding-right: 5rem !important; }

.u-pr-6 {
  padding-right: 6rem !important; }

.u-pr-7 {
  padding-right: 7rem !important; }

.u-pr-8 {
  padding-right: 8rem !important; }

.u-pr-9 {
  padding-right: 9rem !important; }

.u-pr-10 {
  padding-right: 10rem !important; }

.u-pr-11 {
  padding-right: 11rem !important; }

.u-pr-12 {
  padding-right: 12rem !important; }

.u-pr-13 {
  padding-right: 13rem !important; }

.u-pr-14 {
  padding-right: 14rem !important; }

.u-pr-15 {
  padding-right: 15rem !important; }

.u-pr-16 {
  padding-right: 16rem !important; }

.u-pr-17 {
  padding-right: 17rem !important; }

.u-pr-18 {
  padding-right: 18rem !important; }

.u-pr-19 {
  padding-right: 19rem !important; }

.u-pr-20 {
  padding-right: 20rem !important; }

.u-pr-21 {
  padding-right: 21rem !important; }

.u-pr-22 {
  padding-right: 22rem !important; }

.u-pr-23 {
  padding-right: 23rem !important; }

.u-pr-24 {
  padding-right: 24rem !important; }

.u-pr-25 {
  padding-right: 25rem !important; }

/* Bottom padding */
.u-pb-0 {
  padding-bottom: 0rem !important; }

.u-pb-1 {
  padding-bottom: 1rem !important; }

.u-pb-2 {
  padding-bottom: 2rem !important; }

.u-pb-3 {
  padding-bottom: 3rem !important; }

.u-pb-4 {
  padding-bottom: 4rem !important; }

.u-pb-5 {
  padding-bottom: 5rem !important; }

.u-pb-6 {
  padding-bottom: 6rem !important; }

.u-pb-7 {
  padding-bottom: 7rem !important; }

.u-pb-8 {
  padding-bottom: 8rem !important; }

.u-pb-9 {
  padding-bottom: 9rem !important; }

.u-pb-10 {
  padding-bottom: 10rem !important; }

.u-pb-11 {
  padding-bottom: 11rem !important; }

.u-pb-12 {
  padding-bottom: 12rem !important; }

.u-pb-13 {
  padding-bottom: 13rem !important; }

.u-pb-14 {
  padding-bottom: 14rem !important; }

.u-pb-15 {
  padding-bottom: 15rem !important; }

.u-pb-16 {
  padding-bottom: 16rem !important; }

.u-pb-17 {
  padding-bottom: 17rem !important; }

.u-pb-18 {
  padding-bottom: 18rem !important; }

.u-pb-19 {
  padding-bottom: 19rem !important; }

.u-pb-20 {
  padding-bottom: 20rem !important; }

.u-pb-21 {
  padding-bottom: 21rem !important; }

.u-pb-22 {
  padding-bottom: 22rem !important; }

.u-pb-23 {
  padding-bottom: 23rem !important; }

.u-pb-24 {
  padding-bottom: 24rem !important; }

.u-pb-25 {
  padding-bottom: 25rem !important; }

/* Left padding */
.u-pl-0 {
  padding-left: 0rem !important; }

.u-pl-1 {
  padding-left: 1rem !important; }

.u-pl-2 {
  padding-left: 2rem !important; }

.u-pl-3 {
  padding-left: 3rem !important; }

.u-pl-4 {
  padding-left: 4rem !important; }

.u-pl-5 {
  padding-left: 5rem !important; }

.u-pl-6 {
  padding-left: 6rem !important; }

.u-pl-7 {
  padding-left: 7rem !important; }

.u-pl-8 {
  padding-left: 8rem !important; }

.u-pl-9 {
  padding-left: 9rem !important; }

.u-pl-10 {
  padding-left: 10rem !important; }

.u-pl-11 {
  padding-left: 11rem !important; }

.u-pl-12 {
  padding-left: 12rem !important; }

.u-pl-13 {
  padding-left: 13rem !important; }

.u-pl-14 {
  padding-left: 14rem !important; }

.u-pl-15 {
  padding-left: 15rem !important; }

.u-pl-16 {
  padding-left: 16rem !important; }

.u-pl-17 {
  padding-left: 17rem !important; }

.u-pl-18 {
  padding-left: 18rem !important; }

.u-pl-19 {
  padding-left: 19rem !important; }

.u-pl-20 {
  padding-left: 20rem !important; }

.u-pl-21 {
  padding-left: 21rem !important; }

.u-pl-22 {
  padding-left: 22rem !important; }

.u-pl-23 {
  padding-left: 23rem !important; }

.u-pl-24 {
  padding-left: 24rem !important; }

.u-pl-25 {
  padding-left: 25rem !important; }

@media (--mobile) {
  /* Top padding */
  .u-pt-sp-0 {
    padding-top: 0rem !important; }
  .u-pt-sp-1 {
    padding-top: 1rem !important; }
  .u-pt-sp-2 {
    padding-top: 2rem !important; }
  .u-pt-sp-3 {
    padding-top: 3rem !important; }
  .u-pt-sp-4 {
    padding-top: 4rem !important; }
  .u-pt-sp-5 {
    padding-top: 5rem !important; }
  .u-pt-sp-6 {
    padding-top: 6rem !important; }
  .u-pt-sp-7 {
    padding-top: 7rem !important; }
  .u-pt-sp-8 {
    padding-top: 8rem !important; }
  .u-pt-sp-9 {
    padding-top: 9rem !important; }
  .u-pt-sp-10 {
    padding-top: 10rem !important; }
  .u-pt-sp-11 {
    padding-top: 11rem !important; }
  .u-pt-sp-12 {
    padding-top: 12rem !important; }
  .u-pt-sp-13 {
    padding-top: 13rem !important; }
  .u-pt-sp-14 {
    padding-top: 14rem !important; }
  .u-pt-sp-15 {
    padding-top: 15rem !important; }
  .u-pt-sp-16 {
    padding-top: 16rem !important; }
  .u-pt-sp-17 {
    padding-top: 17rem !important; }
  .u-pt-sp-18 {
    padding-top: 18rem !important; }
  .u-pt-sp-19 {
    padding-top: 19rem !important; }
  .u-pt-sp-20 {
    padding-top: 20rem !important; }
  .u-pt-sp-21 {
    padding-top: 21rem !important; }
  .u-pt-sp-22 {
    padding-top: 22rem !important; }
  .u-pt-sp-23 {
    padding-top: 23rem !important; }
  .u-pt-sp-24 {
    padding-top: 24rem !important; }
  .u-pt-sp-25 {
    padding-top: 25rem !important; }
  /* Right padding */
  .u-pr-sp-0 {
    padding-right: 0rem !important; }
  .u-pr-sp-1 {
    padding-right: 1rem !important; }
  .u-pr-sp-2 {
    padding-right: 2rem !important; }
  .u-pr-sp-3 {
    padding-right: 3rem !important; }
  .u-pr-sp-4 {
    padding-right: 4rem !important; }
  .u-pr-sp-5 {
    padding-right: 5rem !important; }
  .u-pr-sp-6 {
    padding-right: 6rem !important; }
  .u-pr-sp-7 {
    padding-right: 7rem !important; }
  .u-pr-sp-8 {
    padding-right: 8rem !important; }
  .u-pr-sp-9 {
    padding-right: 9rem !important; }
  .u-pr-sp-10 {
    padding-right: 10rem !important; }
  .u-pr-sp-11 {
    padding-right: 11rem !important; }
  .u-pr-sp-12 {
    padding-right: 12rem !important; }
  .u-pr-sp-13 {
    padding-right: 13rem !important; }
  .u-pr-sp-14 {
    padding-right: 14rem !important; }
  .u-pr-sp-15 {
    padding-right: 15rem !important; }
  .u-pr-sp-16 {
    padding-right: 16rem !important; }
  .u-pr-sp-17 {
    padding-right: 17rem !important; }
  .u-pr-sp-18 {
    padding-right: 18rem !important; }
  .u-pr-sp-19 {
    padding-right: 19rem !important; }
  .u-pr-sp-20 {
    padding-right: 20rem !important; }
  .u-pr-sp-21 {
    padding-right: 21rem !important; }
  .u-pr-sp-22 {
    padding-right: 22rem !important; }
  .u-pr-sp-23 {
    padding-right: 23rem !important; }
  .u-pr-sp-24 {
    padding-right: 24rem !important; }
  .u-pr-sp-25 {
    padding-right: 25rem !important; }
  /* Bottom padding */
  .u-pb-sp-0 {
    padding-bottom: 0rem !important; }
  .u-pb-sp-1 {
    padding-bottom: 1rem !important; }
  .u-pb-sp-2 {
    padding-bottom: 2rem !important; }
  .u-pb-sp-3 {
    padding-bottom: 3rem !important; }
  .u-pb-sp-4 {
    padding-bottom: 4rem !important; }
  .u-pb-sp-5 {
    padding-bottom: 5rem !important; }
  .u-pb-sp-6 {
    padding-bottom: 6rem !important; }
  .u-pb-sp-7 {
    padding-bottom: 7rem !important; }
  .u-pb-sp-8 {
    padding-bottom: 8rem !important; }
  .u-pb-sp-9 {
    padding-bottom: 9rem !important; }
  .u-pb-sp-10 {
    padding-bottom: 10rem !important; }
  .u-pb-sp-11 {
    padding-bottom: 11rem !important; }
  .u-pb-sp-12 {
    padding-bottom: 12rem !important; }
  .u-pb-sp-13 {
    padding-bottom: 13rem !important; }
  .u-pb-sp-14 {
    padding-bottom: 14rem !important; }
  .u-pb-sp-15 {
    padding-bottom: 15rem !important; }
  .u-pb-sp-16 {
    padding-bottom: 16rem !important; }
  .u-pb-sp-17 {
    padding-bottom: 17rem !important; }
  .u-pb-sp-18 {
    padding-bottom: 18rem !important; }
  .u-pb-sp-19 {
    padding-bottom: 19rem !important; }
  .u-pb-sp-20 {
    padding-bottom: 20rem !important; }
  .u-pb-sp-21 {
    padding-bottom: 21rem !important; }
  .u-pb-sp-22 {
    padding-bottom: 22rem !important; }
  .u-pb-sp-23 {
    padding-bottom: 23rem !important; }
  .u-pb-sp-24 {
    padding-bottom: 24rem !important; }
  .u-pb-sp-25 {
    padding-bottom: 25rem !important; }
  /* Left padding */
  .u-pl-sp-0 {
    padding-left: 0rem !important; }
  .u-pl-sp-1 {
    padding-left: 1rem !important; }
  .u-pl-sp-2 {
    padding-left: 2rem !important; }
  .u-pl-sp-3 {
    padding-left: 3rem !important; }
  .u-pl-sp-4 {
    padding-left: 4rem !important; }
  .u-pl-sp-5 {
    padding-left: 5rem !important; }
  .u-pl-sp-6 {
    padding-left: 6rem !important; }
  .u-pl-sp-7 {
    padding-left: 7rem !important; }
  .u-pl-sp-8 {
    padding-left: 8rem !important; }
  .u-pl-sp-9 {
    padding-left: 9rem !important; }
  .u-pl-sp-10 {
    padding-left: 10rem !important; }
  .u-pl-sp-11 {
    padding-left: 11rem !important; }
  .u-pl-sp-12 {
    padding-left: 12rem !important; }
  .u-pl-sp-13 {
    padding-left: 13rem !important; }
  .u-pl-sp-14 {
    padding-left: 14rem !important; }
  .u-pl-sp-15 {
    padding-left: 15rem !important; }
  .u-pl-sp-16 {
    padding-left: 16rem !important; }
  .u-pl-sp-17 {
    padding-left: 17rem !important; }
  .u-pl-sp-18 {
    padding-left: 18rem !important; }
  .u-pl-sp-19 {
    padding-left: 19rem !important; }
  .u-pl-sp-20 {
    padding-left: 20rem !important; }
  .u-pl-sp-21 {
    padding-left: 21rem !important; }
  .u-pl-sp-22 {
    padding-left: 22rem !important; }
  .u-pl-sp-23 {
    padding-left: 23rem !important; }
  .u-pl-sp-24 {
    padding-left: 24rem !important; }
  .u-pl-sp-25 {
    padding-left: 25rem !important; } }

.js-parallax {
  overflow: hidden; }
  .js-parallax img {
    transform: scale(1.1) translateY(20px); }
  .js-parallax--reverse img {
    transform: scale(1.1) translateY(-20px); }

/* ==========================================================================
 text-align
========================================================================== */
.u-ta-l {
  text-align: left !important; }

.u-ta-c {
  text-align: center !important; }

.u-ta-r {
  text-align: right !important; }

@media (--mobile) {
  .u-ta-l-sp {
    text-align: left !important; }
  .u-ta-c-sp {
    text-align: center !important; }
  .u-ta-r-sp {
    text-align: right !important; } }

.u-1col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u-2col {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; }

@media (--large) {
  .u-1col-pc {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .u-2col-pc {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; } }

@media (--mobile) {
  .u-1col-sp {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .u-2col-sp {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; } }

/**
 * BrowserSync
 */
#__bs_notify__ {
  display: none !important;
  font-size: 8px !important;
  opacity: .25;
  max-height: 50px; }

.__debug_mihon__ {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto 0 auto;
  z-index: 100000;
  pointer-events: none;
  cursor: none;
  width: 100%;
  overflow: hidden;
  text-align: center;
  animation: __debug_mihon__ 1s infinite; }

.__debug_mihon__ {
  padding-top: 0px;
  margin-top: 0px; }

@-webkit-keyframes __debug_mihon__ {
  0% {
    opacity: 0; }
  60% {
    opacity: .5; }
  100% {
    opacity: .5; } }

/*
@example
 <div class="mihon">
  <img src="./images/sample1.png" alt="" class="type_pc">
  <img src="./images/sample2.png" alt="" class="type_sp">
</div>
*/
.a-blog {
  width: 100%; }
  .a-blog .article-hover {
    margin-left: 4.1rem; }
    .a-blog .article-hover:nth-child(n + 6) {
      margin-top: 5.1rem; }
      @media (--mobile) {
        .a-blog .article-hover:nth-child(n + 6) {
          margin-top: 0; } }
    .a-blog .article-hover article {
      transition: .3s; }
      .a-blog .article-hover article::before {
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .5s ease-in-out; }
    @media (--mobile) {
      .a-blog .article-hover {
        padding-top: 1rem;
        margin-left: 0; } }
    @media (--large) {
      .a-blog .article-hover .hover-1:hover img {
        transform: scale(1.1, 1.1);
        transition: 0.1s; }
      .a-blog .article-hover .hover-2 a:hover {
        opacity: 0.6;
        transition: 0.1s; } }
  .a-blog.is__hidden {
    opacity: 0; }
  .a-blog .article-body {
    margin-top: 5.5rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    transition: max-height 0.3s linear; }
    @media (--mobile) {
      .a-blog .article-body {
        display: block;
        margin-top: 0;
        margin-right: 0; } }
    .a-blog .article-body.is-active {
      max-height: 100%;
      height: 100%; }
  .a-blog .more-btn.is-active {
    display: none; }

.a-news .article-hover article {
  transition: .3s; }
  .a-news .article-hover article::before {
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .5s ease-in-out; }

@media (--large) {
  .a-news .article-hover:hover article::before {
    transform-origin: left;
    transform: scaleX(1); }
  .a-news .article-hover:hover article p {
    opacity: 0.6; } }

.a-news.is__hidden {
  opacity: 0; }

.a-news .article-body {
  height: 100%;
  overflow: hidden;
  max-height: 86rem;
  transition: 1s; }
  .a-news .article-body.is-active {
    max-height: 100%;
    height: 100%; }

.a-news .more-btn {
  transition: .5s; }
  .a-news .more-btn.is-active span {
    transform: rotate(270deg); }

.p-contact-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3rem; }
  @media (--mobile) {
    .p-contact-list {
      flex-direction: column;
      gap: 1rem; } }
  .p-contact-list__ttl {
    width: 33%;
    padding-right: 2rem;
    font-size: 1.6rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 5rem;
    align-items: center; }
    @media (--mobile) {
      .p-contact-list__ttl {
        width: 100%;
        justify-content: flex-start;
        gap: 1rem;
        height: auto; } }
    .p-contact-list__ttl--require::after {
      content: '必須';
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 2rem;
      background-color: #FCEA00;
      border-radius: .4rem;
      font-size: 1.2rem; }
    .p-contact-list__ttl--no-input {
      height: auto; }
  .p-contact-list__input {
    width: 67%; }
    @media (--mobile) {
      .p-contact-list__input {
        width: 100%; } }
    .p-contact-list__input input[type=text], .p-contact-list__input input[type=email] {
      width: 100%;
      height: 5rem;
      line-height: 5rem;
      font-size: 1.6rem;
      border: solid 1px #B5B5B6;
      border-radius: .6rem;
      padding: 0 2rem; }
    .p-contact-list__input textarea {
      width: 100%;
      font-size: 1.6rem;
      border: solid 1px #B5B5B6;
      border-radius: .6rem;
      padding: 1rem 2rem; }
    .p-contact-list__input .mwform-checkbox-field label {
      cursor: pointer; }
      .p-contact-list__input .mwform-checkbox-field label > span {
        display: flex;
        gap: 1rem;
        position: relative; }
        .p-contact-list__input .mwform-checkbox-field label > span::before {
          content: '';
          display: block;
          width: 3.2rem;
          height: 3.2rem;
          border-radius: .6rem;
          border: solid 1px #B5B5B6; }
          @media (--mobile) {
            .p-contact-list__input .mwform-checkbox-field label > span::before {
              width: 2.5rem;
              height: 2.5rem; } }
      .p-contact-list__input .mwform-checkbox-field label > input:checked + span::after {
        content: '';
        display: block;
        width: 3.2rem;
        height: 3.2rem;
        background-color: #000000;
        mask-image: url(../svg/ic-check.svg);
        mask-size: 1.7rem 1.2rem;
        mask-position: center;
        mask-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0; }
        @media (--mobile) {
          .p-contact-list__input .mwform-checkbox-field label > input:checked + span::after {
            width: 2.5rem;
            height: 2.5rem; } }
  .p-contact-list__select-wrap {
    width: 100%;
    height: 5rem;
    line-height: 5rem;
    font-size: 1.6rem;
    border: solid 1px #B5B5B6;
    border-radius: .6rem;
    padding: 0 1.5rem; }
    .p-contact-list__select-wrap select {
      width: 100%;
      border: none;
      outline: none; }
      .p-contact-list__select-wrap select:focus, .p-contact-list__select-wrap select:active {
        border: none;
        outline: none; }
  .p-contact-list__pp {
    display: none; }
    .p-contact-list__pp-remarks {
      padding-left: 4.2rem;
      margin-top: .5rem; }
      @media (--mobile) {
        .p-contact-list__pp-remarks {
          padding-left: 0; } }
      .p-contact-list__pp-remarks a {
        position: relative; }
        .p-contact-list__pp-remarks a::before {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: #B5B5B6;
          position: absolute;
          bottom: -.3rem;
          left: 0; }

.p-contact-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 28rem;
  height: 8rem;
  margin-left: 33%;
  font-size: 1.6rem;
  padding: 0 2.2rem;
  margin-top: 5rem;
  position: relative;
  border: solid 1px #B5B5B6;
  border-radius: 1rem;
  transition: border .5s; }
  @media (--mobile) {
    .p-contact-btn {
      margin-left: auto;
      margin-right: auto; } }
  .p-contact-btn::before {
    content: '';
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 1rem;
    transition: opacity .5s;
    border: solid 5px #FCEA00;
    z-index: 1;
    opacity: 0; }
  .p-contact-btn > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.4rem;
    height: 4.4rem;
    background-color: #F1F1F1;
    border-radius: 100%;
    overflow: hidden;
    position: relative; }
    .p-contact-btn > span::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #FCEA00;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0, 100%);
      transition: transform .5s; }
    .p-contact-btn > span::after {
      content: '';
      display: block;
      width: .5rem;
      height: 1rem;
      background-color: #000000;
      mask-image: url(../svg/ic-arrow.svg);
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      position: relative; }
  .p-contact-btn:hover {
    border-color: transparent; }
    .p-contact-btn:hover::before {
      opacity: 1; }
    .p-contact-btn:hover > span::before {
      transform: translate(0, 0%); }
  .p-contact-btn--back {
    margin-top: 3rem; }
    .p-contact-btn--back > span::after {
      transform: rotateZ(180deg); }
  .p-contact-btn--submit {
    margin-top: 1rem; }

.mw_wp_form_confirm .p-contact-list {
  margin-bottom: 4rem; }
  .mw_wp_form_confirm .p-contact-list__ttl {
    height: auto; }
  .mw_wp_form_confirm .p-contact-list__pp-remarks {
    padding-left: 0; }

.s-blog .article-hover {
  padding-top: 6rem; }
  .s-blog .article-hover article {
    transition: .3s; }
    .s-blog .article-hover article::before {
      transform-origin: right;
      transform: scaleX(0);
      transition: transform .5s ease-in-out; }
  @media (--mobile) {
    .s-blog .article-hover {
      width: 50%; } }
  @media (--large) {
    .s-blog .article-hover:hover article::before {
      transform-origin: left;
      transform: scaleX(1); }
    .s-blog .article-hover:hover article p {
      opacity: 0.6; } }

.s-blog .article-body {
  margin-top: 8rem;
  width: 91%;
  display: flex;
  overflow: hidden;
  height: 100%;
  max-height: 86rem;
  transition: max-height 0.3s linear; }
  .s-blog .article-body.is-active {
    max-height: 100%;
    height: 100%; }
  @media (--mobile) {
    .s-blog .article-body {
      margin-top: 0;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr); } }

.s-blog__body > *:nth-child(n + 2) {
  margin-top: 2rem; }

.s-blog h2 {
  position: relative;
  display: block;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.4;
  color: #231815;
  margin-top: 5rem;
  margin-bottom: 2.4rem;
  padding-left: 2rem; }
  .s-blog h2::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #FCEA00; }

.s-blog p {
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 2;
  color: #231815;
  margin-bottom: 3rem; }

.s-blog .unable-link {
  pointer-events: none;
  opacity: 0.6; }
  .s-blog .unable-link p {
    color: #DBDBDB !important; }

.no_text {
  margin: auto;
  padding-top: 0 !important; }

.s-news__body > *:nth-child(n + 2) {
  margin-top: 2rem; }

.s-news h2 {
  position: relative;
  display: block;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.4;
  color: #231815;
  margin-top: 5rem;
  margin-bottom: 2.4rem;
  padding-left: 2rem; }
  .s-news h2::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #FCEA00; }

.s-news h3 {
  display: block;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.4;
  color: #231815;
  margin-top: 5rem;
  margin-bottom: 2rem; }

.s-news p {
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 2;
  color: #231815;
  margin-bottom: 3rem; }

.s-news a {
  color: #FCEA00;
  border-bottom: 1px solid #FCEA00;
  transition: .3s; }
  @media (--large) {
    .s-news a:hover {
      opacity: 0.5; } }

.s-news .unable-link {
  pointer-events: none;
  opacity: 0.6; }
  .s-news .unable-link p {
    color: #DBDBDB !important; }

.s-news ul, .s-news ul.first {
  padding-left: 1em;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: repeat(7, 1fr); }
  .s-news ul li, .s-news ul.first li {
    padding-top: 1rem; }
  .s-news ul li.subject, .s-news ul.first li.subject {
    padding-left: 1em;
    position: relative; }
    .s-news ul li.subject::before, .s-news ul.first li.subject::before {
      content: "■";
      position: absolute;
      top: 65%;
      transform: translateY(-50%);
      left: 0; }

.s-news ul.first {
  grid-template-rows: repeat(3, 1fr) 5fr; }

.s-news .line {
  display: block;
  position: relative; }
  .s-news .line::before {
    position: absolute;
    content: "";
    top: -1em;
    left: 10%;
    width: 80%;
    height: 1px;
    background-color: #DBDBDB; }

.p-service-section-header {
  @apply rounded-l-[3rem]
      overflow-hidden
      relative
      h-auto
      before:pt-[45%]
      before:block
      sp:rounded-[0]
      sp:before:pt-[85%]; }
  .p-service-section-header__bg {
    @apply w-full
        h-full
        absolute
        inset-0
        object-cover; }
  .p-service-section-header__nav {
    @apply absolute
        top-[50%]
        left-[50%]
        translate-x-[-50%]
        translate-y-[-50%]
        z-[1]
        bg-[rgba(255,255,255,0.9)]
        rounded-[2rem]
        text-center
        pt-[6rem]
        pb-[2rem]
        px-[2rem]
        w-[22rem]; }
  .p-service-section-header__icon {
    @apply w-[7rem]
        h-[7rem]
        absolute
        top-[-2.4rem]
        left-[50%]
        translate-x-[-50%]; }
  .p-service-section-header__ttl {
    @apply flex
        flex-col
        items-center
        gap-[0]
        mb-[1rem]; }
    .p-service-section-header__ttl-sub {
      @apply text-[1.4rem]
          text-[#B5B5B6]; }
    .p-service-section-header__ttl-main {
      @apply text-[2.54rem]
          inline-block
          relative
          before:absolute
          before:block
          before:w-full
          before:h-[.3rem]
          before:bg-[#FFE300]
          before:bottom-[0]
          before:left-[0]; }
  .p-service-section-header__nav-list {
    @apply flex
        flex-col
        gap-[.5rem]
        w-[12rem]
        mx-auto
        [&>li]:w-full
        [&>li]:h-[3rem]
        [&>li]:flex
        [&>li]:items-center
        [&>li]:justify-center
        [&>li]:rounded-[1.4rem]
        [&>li]:bg-[#FCEA00]
        [&>li]:text-[1.3rem]; }

.p-service-dots-bg {
  @apply before:bg-[white]
      before:w-full
      before:h-[2px]
      before:absolute
      before:left-0
      before:top-[1.2rem]; }
  .p-service-dots-bg:before {
    background-image: linear-gradient(to right, #B5B5B6 2px, transparent 2px);
    background-size: 7px; }

.p-service-accordion {
  @apply pr-[6rem]
      flex
      flex-col
      mb-[1.6rem]

      sp:pr-[0]; }
  .p-service-accordion__ttl {
    @apply w-full
        flex
        items-center
        justify-between
        px-[2rem]
        bg-[#FCEA00]
        rounded-[.5rem]
        h-[5.4rem]
        text-[1.8rem]
        cursor-pointer

        sp:leading-[1.8]
        sp:pr-[1rem]; }
  .p-service-accordion__toggle {
    @apply text-[1.3rem]
        flex
        items-center
        bg-[white]
        rounded-[1.3rem]
        gap-[1rem]
        px-[1.2rem]
        h-[2.6rem]
        pointer-events-none

        sp:rounded-[100%]
        sp:px-[0]
        sp:w-[3rem]
        sp:h-[3rem]
        sp:justify-center; }
    .p-service-accordion__toggle-mark {
      @apply block
          relative
          w-[1.1rem]
          h-[1.1rem]
          before:w-[100%]
          before:h-[1px]
          before:bg-[#231815]
          before:absolute
          before:left-[0]
          before:top-[50%]
          after:h-[100%]
          after:w-[1px]
          after:bg-[#231815]
          after:absolute
          after:top-[0]
          after:left-[50%]
          before:origin-center
          after:origin-center
          before:translate-y-[-50%]
          after:translate-x-[-50%]
          before:duration-[.3s]
          after:duration-[.3s]; }
      .p-service-accordion__toggle-mark.is-active {
        @apply before:rotate-[45deg]
            before:top-[50%]
            
            after:rotate-[45deg]
            after:left-[50%]; }
  .p-service-accordion__data {
    @apply p-[3rem]
        hidden

        sp:py-[2rem]
        sp:px-[0]
        sp:gap-[1.5rem]; }
    .p-service-accordion__data-inner {
      @apply flex
          flex-wrap
          justify-between
          gap-[2.2rem]; }

.p-service-menu-list {
  @apply relative
      pl-[1.5rem]
      before:w-[.7rem]
      before:h-[.7rem]
      before:rounded-[100%]
      before:bg-[#FCEA00]
      before:absolute
      before:top-[1.2rem]
      before:left-[0]; }
  .p-service-menu-list__ttl {
    @apply p-service-dots-bg
        text-[1.6rem]
        relative
        [&>span]:relative
        [&>span]:bg-[white]
        [&>span]:pr-[1rem]; }
  .p-service-menu-list__data {
    @apply flex
        justify-between
        relative; }

.p-top .p-heading {
  @apply [&>small]:text-[1.8rem]
      [&>small]:font-en
      [&>small]:text-[#B5B5B6]

      [&>small]:sp:text-[1.6rem]

      [&>b]:text-[3.2rem]
      [&>b]:border-b-[0.3rem]
      [&>b]:border-[#FCEA00]
      [&>b]:pb-[0.8rem]
      [&>b]:font-[100]

      [&>b]:sp:text-[2rem]; }

.p-top .s-service__link a:hover > figure img {
  transform: scale(1.1); }

.p-top .s-service__link a:hover > figure::after {
  opacity: 1; }

@media (--large) {
  .p-top .p-hover-01 > div:hover > picture {
    opacity: 1; }
  .p-top .p-hover-01 > div:hover > div:first-of-type h2 b {
    border-color: #fff; }
  .p-top .p-hover-01 > div:hover > div:first-of-type * {
    color: #fff; }
  .p-top .p-hover-01 > div:hover > div:last-of-type {
    color: #fff; }
    .p-top .p-hover-01 > div:hover > div:last-of-type div {
      border-color: #fff; }
  .p-top .p-blog-swiper li figure {
    overflow: hidden; }
    .p-top .p-blog-swiper li figure img {
      transition: 0.5s; }
  .p-top .p-blog-swiper li a:hover + figure img {
    transform: scale(1.1); } }
