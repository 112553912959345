.s-news {

  &__body > *:nth-child(n + 2) {
    margin-top: 2rem;
  }

  h2 {
    position: relative;
    display: block;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 1.4;
    color: #231815;
    margin-top: 5rem;
    margin-bottom: 2.4rem;
    padding-left: 2rem;

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: #FCEA00;
    }
  }

  h3 {
    display: block;
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 1.4;
    color: #231815;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }

  p {
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 2;
    color: #231815;
    margin-bottom: 3rem;
  }

  a {
    color: #FCEA00;
    border-bottom: 1px solid #FCEA00;
    transition: .3s;
    @media (--large){
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .unable-link{
    pointer-events: none;
    opacity: 0.6;
    
    p {
      color: #DBDBDB !important;
    }
  }
  ul, ul.first{
    padding-left:1em;
    display:grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: repeat(7,1fr);
    li{
      padding-top: 1rem;
    }
    li.subject{
      padding-left: 1em;
      position: relative;
      &::before{
        content:"■";
        position:absolute;
        top:65%;
        transform: translateY(-50%);
        left: 0;
      }
    }
    // .content{
    //   .picture{
    //     .image01{
    //       background-image: url(../../../../public/common/images/news/dammy.jpg);
    //       width:30%;
    //       height:auto;
    //     }
    //   }
    // }
  
  }
  ul.first{
    grid-template-rows: repeat(3,1fr) 5fr;
  }
  .line{
    display: block;
    position: relative;
    &::before{
      position: absolute;
      content: "";
      top: -1em;
      left: 10%;
      width: 80%;
      height: 1px;
      background-color: #DBDBDB;
    }
  }
}