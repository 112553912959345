.p-top {
  .p-heading {
    @apply
      [&>small]:text-[1.8rem]
      [&>small]:font-en
      [&>small]:text-[#B5B5B6]

      [&>small]:sp:text-[1.6rem]

      [&>b]:text-[3.2rem]
      [&>b]:border-b-[0.3rem]
      [&>b]:border-[#FCEA00]
      [&>b]:pb-[0.8rem]
      [&>b]:font-[100]

      [&>b]:sp:text-[2rem];
  }

  .s-service {
    &__link {
      a {
        &:hover {
          & > figure {
            img {
              transform: scale(1.1);
            }

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  @media (--large) {
    .p-hover-01 {
      & > div {
        &:hover {
          & > picture {
            opacity: 1;
          }

          & > div {
            &:first-of-type {
              h2 b {
                border-color: #fff;
              }

              * {
                color: #fff;
              }
            }

            &:last-of-type {
              color: #fff;

              div {
                border-color: #fff;
              }
            }
          }
        }
      }
    }

    .p-blog-swiper {
      li {
        figure {
          overflow: hidden;

          img {
            transition: 0.5s;
          }
        }

        a {
          &:hover {
            & + figure {
              img {
                transform: scale(1.1);
              }
            }
          }
        }
      }
    }
  }
}
