/* ==========================================================================
 font-weigth
========================================================================== */

/* font-weigth */
.u-fw-l {
  font-weight: lighter !important;
}

.u-fw-n {
  font-weight: normal !important;
}

.u-fw-b {
  font-weight: bold !important;
}

@media (--mobile) {
  /* font-weigth */
  .u-fw-sp-l {
    font-weight: lighter !important;
  }

  .u-fw-sp-n {
    font-weight: normal !important;
  }

  .u-fw-sp-b {
    font-weight: bold !important;
  }
}
