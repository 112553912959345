@charset "utf-8";

// Foundation
@import "foundation/setting/_animation.scss";
@import "foundation/setting/_font.scss";
@import "foundation/setting/_function.scss";
@import "foundation/setting/_inview.scss";
@import "foundation/setting/_mediaquery.scss";
@import "foundation/setting/_mixin.scss";
@import "foundation/setting/_regulation.scss";
@import "foundation/setting/_sprite.scss";
@import "foundation/setting/_timing.scss";

// vendor
@import "normalize.css"; /* == @import "../node_modules/normalize.css/normalize.css"; */
@import "swiper/swiper-bundle.min.css";

@import "foundation/base/_vue";
@import "foundation/base/_structure";
@import "foundation/base/_tailwind";

// Layout
@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_main.scss";
@import "layout/_sidebar.scss";

// Object/Component
@import "object/component/_bread.scss";
@import "object/component/_btn.scss";
@import "object/component/_c-breadcrumb.scss";
@import "object/component/_c-btn.scss";
@import "object/component/_c-heading.scss";
@import "object/component/_c-hover.scss";
@import "object/component/_c-inner.scss";
@import "object/component/_flex.scss";
@import "object/component/_form.scss";
@import "object/component/_iframe.scss";
@import "object/component/_pagination.scss";
@import "object/component/_sns.scss";
@import "object/component/_wordpress.scss";

// Object/Component
@import "object/utility/_helper.scss";
@import "object/utility/_u-fontSize.scss";
@import "object/utility/_u-fontweigth.scss";
@import "object/utility/_u-helper.scss";
@import "object/utility/_u-lineHeight.scss";
@import "object/utility/_u-margin.scss";
@import "object/utility/_u-objectFit.scss";
@import "object/utility/_u-padding.scss";
@import "object/utility/_u-parallax.scss";
@import "object/utility/_u-textAlign.scss";
@import "object/utility/_u-textOverflow.scss";

// debug
@import "foundation/base/_debug";

//pages
@import "object/project/_a-blog.scss";
@import "object/project/_a-news.scss";
@import "object/project/_p-contact.scss";
@import "object/project/_s-blog.scss";
@import "object/project/_s-news.scss";
@import "object/project/_service.scss";
@import "object/project/_top.scss";
