/* ==========================================================================
 line-height
========================================================================== */

/* line-height */
@for $value from 0 to 50 {
  .u-lh-#{$value} {
    line-height: $value * 0.1 !important;
  }
}

@media (--mobile) {
  /* line-height */
  @for $value from 0 to 50 {
    .u-lh-sp-#{$value} {
      line-height: $value * 0.1 !important;
    }
  }
}
