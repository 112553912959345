.s-blog {
  .article-hover {
    padding-top: 6rem;
    article {
      transition: .3s;
      &::before {
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .5s ease-in-out;
      }
    }
    @media (--mobile) {
      width: 50%;
    }
    @media (--large){
      &:hover {
        article {
          &::before {
            transform-origin: left;
            transform: scaleX(1);
          }

          p {
            opacity:  0.6;
          }
        }
      }
    }
  }

  .article-body {
    margin-top: 8rem;
    width: 91%;
    display: flex;
    overflow: hidden;
    height: 100%;
    max-height: 86rem;
    transition: max-height 0.3s linear;

    &.is-active {
      max-height: 100%;
      height: 100%;
    }
    @media (--mobile) {
      margin-top: 0;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2,1fr);

    }
  }





  &__body > *:nth-child(n + 2) {
    margin-top: 2rem;
  }
  h2 {
    position: relative;
    display: block;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 1.4;
    color: #231815;
    margin-top: 5rem;
    margin-bottom: 2.4rem;
    padding-left: 2rem;

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: #FCEA00;
    }
  }

  p {
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 2;
    color: #231815;
    margin-bottom: 3rem;
  }

  .unable-link{
    pointer-events: none;
    opacity: 0.6;
    p {
      color: #DBDBDB !important;
    }
  }
}
.no_text{
  margin: auto;
  padding-top: 0 !important;
}