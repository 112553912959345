.c-heading {
  &_type01 {
    @apply
      relative
      mt-[10.4rem]
      ml-[0]
      text-[4.2rem]
      leading-[1.4]

      sp:mt-[6rem]
      sp:text-[3.2rem];

    &::after {
      @apply
        absolute
        left-[-0.4rem]
        top-[0.9rem]
        h-[1.5rem]
        w-[1.5rem]
        rounded-[9999px]
        bg-[#FCEA00]
        content-[""]
        z-[-1]

        sp:top-[0.5rem]
        sp:left-[-0.6rem];
    }

    small {
      @apply
        block
        text-[1.8rem]
        text-[#B5B5B6];
    }
  }

  &_type02 {
    @apply
      flex
      pl-[2rem]
      text-[1.8rem]
      relative;

    &::after {
      @apply
        content-[""]
        absolute
        h-[1rem]
        w-[1rem]
        bg-[#FCEA00]
        left-0
        top-[0.9rem]
        rounded-[9999px];
    }

    span {
      @apply
        leading-[1.5];
    }
  }
}

.c-heading-num {
  @apply
    text-[2rem]
    pl-[4rem]
    mt-[5.5rem]
    mb-[2.5rem]
    relative
    [counter-increment:headingCount]

    sp:text-[1.8rem]

    before:[content:counter(headingCount,decimal-leading-zero)]
    before:font-en
    before:text-[#FCEA00]
    before:text-[2.8rem]
    before:absolute
    before:top-[50%]
    before:left-[0]
    before:translate-y-[-50%]
  ;
}