.c-breadcrumb {
  @apply
    relative
    z-[1]
    flex
    justify-end
    pr-[10rem]
    mt-[-2rem]

    sp:pr-0
    sp:mt-[1rem];

  a {
    @apply
      relative
      pr-[1.5rem]
      mr-[1.5rem];

    &::after {
      @apply
        absolute
        content-[""]
        h-[50%]
        w-[0.1rem]
        right-0
        top-[50%]
        translate-y-[-50%]
        rotate-[30deg]
        bg-[#000];
    }
  }
}
