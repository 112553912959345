.c-btn-general {
  @apply
    [&>a]:border-[1px]
    [&>a]:rounded-[1rem]
    [&>a]:pl-[2.5rem]
    [&>a]:pt-[2.8rem]
    [&>a]:pb-[2.8rem]
    [&>a]:pr-[2rem]
    [&>a]:block
    [&>a]:mx-auto
    [&>a]:w-[28rem]

    [&>a>div]:relative

    [&>a>div>p]:text-[1.6rem]
    [&>a>div>p]:w-[calc(100%-5.2rem)]

    [&>a>div>svg]:w-[4.4rem]

    [&>a>div>svg]:absolute
    [&>a>div>svg]:top-[50%]
    [&>a>div>svg]:right-0
    [&>a>div>svg]:translate-y-[-50%];
}


.c-btn-normal {
  @apply
    [&>a]:inline-flex
    [&>a]:items-center
    [&>a]:font-en

    [&>a>span]:font-en

    [&>a>div]:bg-[#fff]
    [&>a>div]:w-[4.1rem]
    [&>a>div]:h-[4.1rem]
    [&>a>div]:flex
    [&>a>div]:items-center
    [&>a>div]:justify-center
    [&>a>div]:border-[1px]
    [&>a>div]:border-[#231815]
    [&>a>div]:ml-[1rem]
    [&>a>div]:rounded-[9999px]
    [&>a>div]:overflow-hidden
    [&>a>div]:relative

    [&>a>div]:after:content-[""]
    [&>a>div]:after:absolute
    [&>a>div]:after:left-0
    [&>a>div]:after:top-0
    [&>a>div]:after:w-[100%]
    [&>a>div]:after:h-[100%]
    [&>a>div]:after:bg-[#FCEA00]
    [&>a>div]:after:translate-y-[100%]
    [&>a>div]:after:duration-[0.5s]
    [&>a>div]:after:pointer-events-none

    [&>a>div>svg]:w-[0.5rem]
    [&>a>div>svg]:relative
    [&>a>div>svg]:z-[1];

  a {
    &:hover {
      & > div {
        @apply
          after:translate-y-[0%];
      }
    }
  }
}
