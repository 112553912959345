.js-parallax {
  overflow: hidden;

  img {
    transform: scale(1.1) translateY(20px);
  }

  &--reverse {
    img {
      transform: scale(1.1) translateY(-20px);
    }
  }
}
