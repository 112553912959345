.a-blog {
  width: 100%;
  .article-hover {
    margin-left: 4.1rem;
    &:nth-child(n + 6){
      margin-top: 5.1rem;
      @media (--mobile) {
        margin-top: 0;
      }
    }
    article {
      transition: .3s;
  
      &::before {
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .5s ease-in-out;
      }
    }
    @media (--mobile) {
      padding-top: 1rem;
      margin-left: 0;
    }
    @media (--large){
      .hover-1:hover {
        img{
          transform:scale( 1.1 , 1.1 );
          transition: 0.1s;
        }
      }
      .hover-2 a:hover{
        opacity: 0.6;
        transition: 0.1s;
      }
    }
  }

  &.is__hidden {
    opacity: 0;
  }

  .article-body {
    margin-top: 5.5rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    // max-height: 105.3rem;
    transition: max-height 0.3s linear;
    @media (--mobile) {
      display: block;
      margin-top: 0;
      // max-height: 100rem;
      margin-right: 0;
    }

    &.is-active {
      max-height: 100%;
      height: 100%;
    }
  }

  .more-btn {
    &.is-active {
      display: none;
    }
  }
}