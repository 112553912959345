.p-contact {
  &-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 3rem;

    @media (--mobile){
      flex-direction: column;
      gap: 1rem;
    }
    &__ttl {
      width: 33%;
      padding-right: 2rem;
      font-size: 1.6rem;
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 5rem;
      align-items: center;

      @media (--mobile){
        width: 100%;
        justify-content: flex-start;
        gap: 1rem;
        height: auto;
      }
      &--require {
        &::after {
          content: '必須';
          display: flex;
          align-items: center;
          justify-content: center;
          width: 4rem;
          height: 2rem;
          background-color: #FCEA00;
          border-radius: .4rem;
          font-size: 1.2rem;
        }
      }
      &--no-input {
        height: auto;
      }
    }
    &__input {
      width: 67%;
      @media (--mobile){
        width: 100%;
      }
      input[type=text],input[type=email] {
        width: 100%;
        height: 5rem;
        line-height: 5rem;
        font-size: 1.6rem;
        border: solid 1px #B5B5B6;
        border-radius: .6rem;
        padding: 0 2rem;
      }
      textarea {
        width: 100%;
        font-size: 1.6rem;
        border: solid 1px #B5B5B6;
        border-radius: .6rem;
        padding: 1rem 2rem;
      }
      .mwform-checkbox-field {
        label {
          cursor: pointer;
          & > span {
            display: flex;
            gap: 1rem;
            position: relative;
            &::before {
              content: '';
              display: block;
              width: 3.2rem;
              height: 3.2rem;
              border-radius: .6rem;
              border: solid 1px #B5B5B6;
              @media (--mobile){
                width: 2.5rem;
                height: 2.5rem;
              }
            }
          }
          & > input:checked + span {
            &::after {
              content: '';
              display: block;
              width: 3.2rem;
              height: 3.2rem;
              background-color: #000000;
              mask-image: url(../svg/ic-check.svg);
              mask-size: 1.7rem 1.2rem;
              mask-position: center;
              mask-repeat: no-repeat;
              position: absolute;
              top: 0;
              left: 0;
              @media (--mobile){
                width: 2.5rem;
                height: 2.5rem;
              }
            }
          }
        }
      }
    }
    &__select-wrap {
      width: 100%;
      height: 5rem;
      line-height: 5rem;
      font-size: 1.6rem;
      border: solid 1px #B5B5B6;
      border-radius: .6rem;
      padding: 0 1.5rem;
      select {
        width: 100%;
        border: none;
        outline: none;
        &:focus,&:active {
          border: none;
          outline: none;
        }
      }
    }
    &__pp {
      display: none;
      &-remarks {
        padding-left: 4.2rem;
        margin-top: .5rem;
        @media (--mobile){
          padding-left: 0;
        }
        a {
          position: relative;
          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: #B5B5B6;
            position: absolute;
            bottom: -.3rem;
            left: 0;
          }
        }
      }
    }
  }
  &-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 28rem;
    height: 8rem;
    margin-left: 33%;
    font-size: 1.6rem;
    padding: 0 2.2rem;
    margin-top: 5rem;
    position: relative;
    border: solid 1px #B5B5B6;
    border-radius: 1rem;
    transition: border .5s;
    @media (--mobile){
      margin-left: auto;
      margin-right: auto;
    }
    &::before {
      content: '';
      display: block;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      position: absolute;
      top: -1px;
      left: -1px;
      border-radius: 1rem;
      transition: opacity .5s;
      border: solid 5px #FCEA00;
      z-index: 1;
      opacity: 0;
    }
    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4.4rem;
      height: 4.4rem;
      background-color: #F1F1F1;
      border-radius: 100%;
      overflow: hidden;
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: #FCEA00;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(0,100%);
        transition: transform .5s;
      }
      &::after {
        content: '';
        display: block;
        width: .5rem;
        height: 1rem;
        background-color: #000000;
        mask-image: url(../svg/ic-arrow.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        position: relative;
      }
    }
    &:hover {
      border-color: transparent;
      &::before {
        opacity: 1;
      }
      & > span {
        &::before {
          transform: translate(0,0%);
        }
      }
    }
    &--back {
      margin-top: 3rem;
      & > span {
        &::after {
          transform: rotateZ(180deg);
        }
      }
    }
    &--submit {
      margin-top: 1rem;
    }
  }
}

.mw_wp_form_confirm {
  .p-contact-list {
    margin-bottom: 4rem;
    &__ttl {
      height: auto;
    }
    &__pp-remarks {
      padding-left: 0;
    }
  }
}