@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @media (--large) {
    font-size: 0.625vw;
  }

  @media (--mobile) {
    font-size: calc(10vw / 414 * 100);
  }

  * {
    @apply font-jp tracking-[0.1em] leading-[1.8];
  }
}

body {
  font-family: $fontfamily;
  font-size: 1.6rem;
  font-feature-settings: "palt";
  letter-spacing: 0;
  &::before {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    position: fixed;
    transition: background-color .5s,opacity .5s;
    pointer-events: none;
    opacity: 0;
    mix-blend-mode: multiply;
  }
  &.is-hum-active {
    &::before {
      background-color: #B1B1B1;
      z-index: 10;
      opacity: 1;
      pointer-events: visible;
    }
  }
}

a {
  color: #000;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

//text
h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
p {
  padding: 0;
  margin: 0;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

img {
  vertical-align: bottom;
}

figure {
  padding: 0;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.l-main {
  line-height: 1.2;
}

svg,
img {
  max-width: 100%;
}

@media (--mobile) {
  html,
  body {
    width: 100%;
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  dl,
  dt,
  dd,
  p {
    padding: 0;
    margin: 0;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

@media (--mobile) {
  .pc {
    display: none !important;
  }
}

@media (--large) {
  [href*="tel:"] {
    pointer-events: none;
  }

  .sp {
    display: none !important;
  }
}
