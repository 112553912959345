.l-footer {
  .f-hvr01 {
    a {
      transition: 0.3s;

      svg path {
        transition: 0.3s;
      }

      &:hover {
        transform: scale(1.1);

        svg path {
          fill: #fcea00;
        }
      }
    }
  }
}
