.a-news {

  .article-hover {
    article {
      transition: .3s;
  
      &::before {
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .5s ease-in-out;
      }
    }

    @media (--large){
      &:hover {
        article {
          &::before {
            transform-origin: left;
            transform: scaleX(1);
          }

          p {
            opacity:  0.6;
          }
        }
      }
    }
  }
  &.is__hidden {
    opacity: 0;
  }

  .article-body {
    height: 100%;
    overflow: hidden;
    max-height: 86rem;
    transition: 1s;

    &.is-active {
      max-height: 100%;
      height: 100%;
    }
  }

  .more-btn {
    transition: .5s;
    &.is-active {
      
      span {
        transform: rotate(270deg);
      }
    }
  }
}